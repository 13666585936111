import IUserResponse from '@/modules/user/interfaces/user-response.interface'
import User from '@/modules/user/models/user.model'
import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse, { IData } from '@/shared/interfaces/response.interface'
import { AxiosResponse } from 'axios';

export default class UserService {
  static async getPaginatedUsers(search: string = '', page: number): Promise<IData<User[]>> {
    return http
      .get(`users?per_page=10&page=${page}&q=${search}`)
      .then((response: IResponse<IModelResponse>) => {
        return {
          data: response.data.data.map((mask: IUserResponse) => new User(mask)),
          meta: response.data.meta
        }
      })
  }

  static async getUserByID(id: number): Promise<User> {
    return http
      .get(`users/${id}`)
      .then((response: IResponse<IUserResponse>) => new User(response.data.data))
  }

  static getTableRowCount(id: string): Promise<any> {
    return http
      .get(`user-list-row-counts/${id}`)
      .then((response: IResponse<any>) => {
        return response.data.row_count
      })
  }

  static setTableRowCount(id: string, number: number): Promise<AxiosResponse> {
    return http
      .get(`user-list-row-counts/${id}/${number}`)
  }
}
