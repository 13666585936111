
































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VuePdfApp from 'vue-pdf-app'
import DocumentService from '@/services/DocumentService'
import 'vue-pdf-app/dist/icons/main.css';
import __ from '@/helpers/__';

@Component({
  components: { VuePdfApp },
  methods: { __ }
})
export default class PdfPreviewModal extends Vue {
  @Prop() readonly pdfId!: string
  @Prop({ default: 'button' }) readonly buttonClass!: string
  @Prop({ default: false }) readonly noButton!: boolean
  modalShown: boolean = false
  loading: boolean = false
  pdfData: ArrayBufferLike | null = null
  modalKey: number = 0
  hasError: boolean = false

  showModal() {
    this.modalShown = true
  }

  async loadPdfData() {
    this.loading = true
    try {
      const response = await DocumentService.getDocumentPDFBase64(this.pdfId)
      const pdfData = new Uint8Array(response.data)
      this.pdfData = pdfData.buffer
      this.modalKey += 1
    } catch (e) {
      this.hasError = true
    } finally {
      this.loading = false
    }
  }

  clearPdfData() {
    this.pdfData = null
    this.hasError = false
  }
}
