




















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import PaymentMethodResponse from '@/modules/settings/interfaces/payment-method-response.interface'
import PaymentMethod from '@/modules/settings/models/payment-method.model'
import { Route } from 'vue-router'
import { GeneralRoutes } from '@/router/routes/general'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import DeleteComponent from '@/modules/settings/DeleteComponent.vue';

@Component({
  components: { PageHeader, LoadingSpinner },
  methods: { __ }
})
export default class PaymentMethodsIndex extends Vue {
  group: string = 'services'
  methods: PaymentMethod[] = []
  loading: boolean = true

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route, oldVal: Route): void {
    if (
      newVal.name === GeneralRoutes.settingsDocuments &&
      oldVal &&
      (
        oldVal.name === GeneralRoutes.documentsPaymentMethodsCreate ||
        (oldVal.name === GeneralRoutes.documentsDeleteType && oldVal.params.type === 'payment-method')
      )
    ) {
      this.getData()
    }
  }

  created(): void {
    this.getData()
  }

  async remove(id: any) {
    this.$router.push({ name: GeneralRoutes.documentsDeleteType, params: { id: id, type: 'payment-methods' } })
  }

  async getData(): Promise<void> {
    this.loading = true
    await http.get('payment-methods')
      .then((response: IResponse<PaymentMethodResponse[]>) => {
        this.methods = response.data.data
      })
      .finally(() => { this.loading = false })
  }

  openDeleteModal(method: PaymentMethod):void {
    this.$store.commit('setModalDialog', {
      component: DeleteComponent,
      id: method.id,
      type: 'payment-methods',
      props: {
        title: __('views.settings.delete.title', { data: method.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }
}
