

























import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general';
import FormBase from '@/shared/classes/components/form/form-base';
import User from '@/modules/user/models/user.model';
import Field from '@/shared/classes/components/form/field';
import { FieldTypes } from '@/shared/components/form/field-types';
import Form from '@/shared/components/form/Form.vue'
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser,
      token: UserGetters.getToken
    })
  }
})
export default class ChangePassword extends Vue {
  GeneralRoutes = GeneralRoutes
  user!: User
  token!: string
  form: FormBase = new FormBase()
    .setEndpoint('auth/password/update')
    .setNoSubmitButton(true)
    .setOnSuccess(() => this.$router.push({ name: GeneralRoutes.user, params: { id: this.user.id.toString() } }))
    .setFields([
      new Field()
        .setKey('password')
        .setType(FieldTypes.password)
        .setTitle(__('views.auth.password')),
      new Field()
        .setKey('password_confirmation')
        .setType(FieldTypes.password)
        .setTitle(__('views.auth.password-confirm'))
    ])
    .setChangeDataBeforeSubmit((data: any) => ({
      ...data,
      email: this.user.email,
      token: this.token
    }))
}
