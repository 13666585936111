







import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import { service } from '@/shared/configs/information-types.config'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import _ from 'lodash'
import moment from 'moment'
import { InvoiceStatusKeys, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import User from '../user/models/user.model'
import { UserGetters } from '@/store/modules/user/user.getters'
import Service from '../service/models/service.model'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import { AxiosResponse } from 'axios';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceCreate extends Vue {
  form: FormBase | any = null
  loadingNumber: boolean = false
  validNumber: boolean = false
  user!: User
  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/invoices')
      .setModel(Invoice)
      .setOnSuccess((response: AxiosResponse) => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: response.data.id } })
      })
      .setCancel(() => this.$router.push({ name: GeneralRoutes.invoicesIndex }))
      .setFields([
        new SearchableField()
          .setKey('client_id')
          .setLoadItemSort('-created_at')
          .setRequiredDecoration(true)
          .setDisplayKey('company_name')
          .setTitle(__('views.invoices.form.client'))
          .setNotFoundComponent(ClientNotFound)
          .loadItems({
            endpoint: '/clients',
            value: 'id',
            title: 'company_name',
            perPage: 9999
          }),
        new Field()
          .setKey('number')
          .setEntryKey('number')
          .setOnChange(_.debounce(this.checkIfValid, 400))
          .setTitle(__('views.invoices.form.number'))
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.date)
          .setKey('date')
          .setRequiredDecoration(true)
          .setEntryKey('date')
          .setTitle(__('views.invoices.form.date'))
          .setSize(FieldSizes.half),
        new ArrayField()
          .setKey('items')
          .setCreateNewRow(true)
          .setShowTotals(true)
          .setEntryKey('items')
          .setFieldsTemplates({ service }),
        new Field()
          .setType(FieldTypes.date)
          .setRequiredDecoration(true)
          .setKey('due_date')
          .setTitle(__('views.invoices.form.dueDate'))
          .setSize(FieldSizes.half),
        new SearchableField()
          .setKey('manager_id')
          .setRequiredDecoration(true)
          .setDisplayKey('full_name')
          .setTitle(__('views.invoices.form.manager'))
          .setLazy(true)
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/users',
            value: 'id',
            title: 'full_name'
          }),
        new Field()
          .setKey('locale')
          .setTitle(__('views.invoices.form.language'))
          .setType(FieldTypes.checkbox)
          .setSize(FieldSizes.fourTwelfth)
          .setCheckedValue('en')
          .setUncheckedValue('lt'),
        new Field()
          .setKey('send_email')
          .setTitle(__('views.invoices.form.send_email'))
          .setSize(FieldSizes.fourTwelfth)
          .setType(FieldTypes.checkbox),
        new Field()
          .setKey('add_sale_deed')
          .setTitle(__('views.invoices.form.add_sale_deed'))
          .setSize(FieldSizes.fourTwelfth)
          .setType(FieldTypes.checkbox),
        new Field()
          .setKey('notes')
          .setEntryKey('notes')
          .setTitle(__('views.invoices.form.notes'))
          .setType(FieldTypes.richEditor)
      ])
      .setInitialValues({
        date: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        client_id: parseInt(this.$route.params.clientId) || null
      })
      .setInjectValues({
        type: InvoiceTypes.standard,
        status: InvoiceStatusKeys.unpaid
      })
      .setChangeDataBeforeSubmit((data: any) => this.formatItems(data))

    await http
      .get(`invoices/get-next-number/${InvoiceTypes.standard}`)
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.form.setData({
          ...this.form.data,
          number: response.data
        })
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })

    this.form.setData({
      ...this.form.data,
      send_email: '1'
    })

    if (this?.$route?.params?.data) {
      const data: any = this.$route.params.data

      const { items, notes, date }: any = data

      const groupField: any = _.find(this.form.fields, (formField: any) => formField.key === 'items')

      if (items) groupField.setChildren([])

      Object.keys(items).forEach((key: string) => {
        groupField.addChildren(groupField.fieldTemplates[items[key].type], 'items', this.form, key)
      })

      this.form.setData({
        ...this.form.data,
        ..._.cloneDeep({
          manager_id: data.manager_id ? data.manager_id : this.user.id,
          client_id: data.client_id,
          date,
          notes,
          items
        })
      })
    } else {
      this.form.setData({
        ...this.form.data,
        manager_id: this.user.id
      })
    }
  }

  formatItems(data: Invoice): Invoice {
    const newData: Invoice = data

    _.each(newData.items, (item: Service) => {
      item.amount = Number(item.amount)
    })

    return newData
  }

  checkIfValid(form: FormBase): any {
    if (!this.loadingNumber && form.data.number) {
      this.loadingNumber = true
      http
        .get(`invoices/check-is-available/${form.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }
}
