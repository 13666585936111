


























































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import User from '@/modules/user/models/user.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { UserStatusData, UserPositionData, UserTypeData } from '@/shared/configs/user/user.config'
import { mapGetters } from 'vuex'
import { UserGetters } from '@/store/modules/user/user.getters'
import UsersEdit from '@/modules/user/UsersEdit.vue';
import UserDisable from '@/modules/user/UsersDisable.vue';

@Component({
  methods: { __ },
  computed: {
    ...mapGetters({
      currentUser: UserGetters.getUser
    })
  }
})
export default class UserPage extends Vue {
  user: User | any = null
  UserPositionData = UserPositionData
  UserTypeData = UserTypeData
  UserStatusData = UserStatusData
  currentUser!: boolean

  mounted(): void {
    this.getData()
  }

  async getData(): Promise<void> {
    await http
      .get(`/users/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.user = new User(item.data)
        this.$root.$emit('headingChange', {
          heading: `${this.user.firstName} ${this.user.lastName}`
        })
      })
  }

  getInitials(user: User): string {
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1)
  }

  openEditModal(): void {
    this.$store.commit('setModalDialog', {
      component: UsersEdit,
      id: this.user.id,
      props: {
        title: __('views.users.edit.title', { user: this.user.fullName })
      }
    })
  }

  openDisableModal(): void {
    this.$store.commit('setModalDialog', {
      component: UserDisable,
      id: this.user.id,
      props: {
        title: __('views.users.disable.text', { user: this.user.title }),
        size: 'md',
        submitLabel: __('form.disable'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }
}
