







import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import ClientsCreate from '@/modules/client/ClientsCreate.vue';
import refreshTable from '@/shared/helpers/data-table/refresh-table';

@Component({
  methods: { __ }
})
export default class ClientNotFound extends Vue {
  @Prop() searchValue!: string

  openAddNewModal():void {
    this.$store.commit('setModalDialog', {
      component: ClientsCreate,
      props: {
        title: __('views.clients.create.title'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      },
      details: {
        companyName: this.searchValue
      }
    })
  }
}
