










































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field from '@/shared/classes/components/form/field'
import Setting from '@/modules/settings/models/setting.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import SettingResponse from '@/modules/settings/interfaces/setting-response.interface'
import PaymentMethodsIndex from '@/modules/settings/PaymentMethodsIndex.vue'
import UnitsIndex from '@/modules/settings/UnitsIndex.vue'
import VATSIndex from '@/modules/settings/VATSIndex.vue'
import DocumentMaskIndex from '@/modules/settings/DocumentMaskIndex.vue'
import MaskCreate from '@/modules/settings/settings-create/MaskCreate.vue';
import VATCreate from '@/modules/settings/settings-create/VATCreate.vue';
import UnitCreate from '@/modules/settings/settings-create/UnitCreate.vue';
import PaymentMethodCreate from '@/modules/settings/settings-create/PaymentMethodsCreate.vue';

@Component({
  components: { PageHeader, Form, PaymentMethodsIndex, UnitsIndex, VATSIndex, DocumentMaskIndex },
  methods: { __ }
})
export default class DocumentSettings extends Vue {
  group: string = 'document'
  form: FormBase = new FormBase()
    .setEndpoint(`/settings/${this.group}`)
    .setModel(Setting)
    .setSuccessAlert('Nustatymai išsaugoti')
    .setFields([
      new Field().setKey('0.value').setTitle(__(`views.settings.form.${this.group}.invoice-title`)),
      new Field().setKey('1.value').setTitle(__(`views.settings.form.${this.group}.prepayment-invoice-title`)),
      new Field().setKey('2.value').setTitle(__(`views.settings.form.${this.group}.offer-title`))
    ])
    .setChangeDataBeforeSubmit(this.postData)

  openPaymentMethodCreateModal():void {
    this.$store.commit('setModalDialog', {
      component: PaymentMethodCreate,
      props: {
        title: __('views.settings.create.payment-methods'),
        size: 'md'
      }
    })
  }

  openUnitCreateModal():void {
    this.$store.commit('setModalDialog', {
      component: UnitCreate,
      props: {
        title: __('views.settings.create.unit'),
        size: 'md'
      }
    })
  }

  openVatCreateModal():void {
    this.$store.commit('setModalDialog', {
      component: VATCreate,
      props: {
        title: __('views.settings.create.vat'),
        size: 'md'
      }
    })
  }

  openMaskCreateModal():void {
    this.$store.commit('setModalDialog', {
      component: MaskCreate,
      props: {
        title: __('views.settings.create.mask'),
        size: 'md'
      }
    })
  }

  async created(): Promise<void> {
    this.form.setLoading(true)
    await http
      .get(`settings/${this.group}`)
      .then((response: IResponse<SettingResponse[]>) => {
        const setting = response.data.data
        this.form.setData({
          ...this.form.data,
          0: {
            value: setting[1].value,
            id: setting[1].id
          },
          1: {
            value: setting[3].value,
            id: setting[3].id
          },
          2: {
            value: setting[5].value,
            id: setting[5].id
          },
          3: {
            value: setting[7].value,
            id: setting[7].id
          }
        })
      })
      .finally(() => this.form.setLoading(false))
  }

  postData(): any {
    const postData: any = { settings: {} }
    Object.keys(this.form.data).forEach((key: string) => {
      postData.settings[key] = {
        ...this.form.data[key],
        value: this.form.data[key].value ? this.form.data[key].value : null
      }
    })
    return postData
  }
}
