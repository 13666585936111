
















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import ModalNavigationable from '@/shared/components/modal-route/ModalNavigationable.vue'
import ReminderTemplate from '@/modules/reminder-template/models/reminder-template.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'

@Component({
  components: { ModalNavigationable },
  methods: { __ }
})
export default class ReminderTemplatesDelete extends Vue {
  GeneralRoutes = GeneralRoutes
  reminderTemplate: ReminderTemplate | any = null

  async created(): Promise<void> {
    await http
      .get(`reminder-templates/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.reminderTemplate = item.data
      })
  }

  get title(): string {
    return this.reminderTemplate ? this.reminderTemplate.title : ''
  }

  confirmDelete(): void {
    http
      .delete(`reminder-templates/${this.reminderTemplate.id}`)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.reminderTemplatesIndex })
      })
  }
}
