import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import ITemplateResponse from '@/modules/offer-template/interfaces/template-response.interface'

export default class Template extends Model<IModelResponse> {
  static ALIAS: string = 'template'

  title!: string
  total!: number
  totalWithVat!: number
  totalVat!: number
  totalDiscount!: number
  information!: any
  notes!: string

  transform(data: ITemplateResponse): void {
    super.transform(data)

    this.title = data.title
    this.total = data.total
    this.totalWithVat = data.total_with_vat
    this.totalVat = data.total_vat
    this.totalDiscount = data.total_discount
    this.information = data.information
    this.notes = data.notes
  }
}
