import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse from '@/shared/interfaces/response.interface'
import DashboardInfo from './models/dashboard-info.model'

export default class DashboardService {
  static async getDashboardInfo(): Promise<DashboardInfo> {
    return http
      .get('dashboards/info')
      .then((response: IResponse<IModelResponse>) => new DashboardInfo(response.data.data))
  }
}
