











import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import PrepaidInvoice from '@/modules/prepaid-invoices/models/prepaid-invoice.model'
import { InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import highlightOverdue from '@/shared/helpers/data-table/highlight-overdue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable },
  methods: { __ }
})
export default class ClientPrepaidInvoices extends Vue {
  table: DataTableBase | null = null

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientPrepaidInvoices)
      .setModel(PrepaidInvoice)
      .setEndpoint(`/clients/${this.$route.params.id}/invoices`)
      .setFilter([{ operator: FilterOperators.equals, type: 'type', value: InvoiceTypes.prepaid }])
      .setRowClassCallback(highlightOverdue)
      .setRowClickCallback((item:PrepaidInvoice) => {
        this.$router.push({ name: GeneralRoutes.prepaidInvoice, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.prepaidInvoice, linkParam: 'id' }),
        new DataTableHeader().setKey('total').setLabel(__('views.invoices.index.table.columns.total')),
        new DataTableHeader().setKey('totalWithVat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('date').setLabel(__('views.invoices.index.table.columns.date')),
        new DataTableHeader().setKey('dueDate').setLabel(__('views.invoices.index.table.columns.dueDate')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.invoices.index.table.columns.status'))
          .setFunctionalClasses((data: PrepaidInvoice) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: PrepaidInvoice) => {
            if (data.status === 'paid') return __('common-names.paid')

            if (data.status === 'partially-paid') return __('common-names.partially-paid')

            if (data.status === 'not-approved') return __('common-names.unapproved')

            return __('common-names.unpaid')
          })
      ])
  }

  private addNewInvoice() {
    this.$router.push({ name: GeneralRoutes.prepaidInvoicesCreate, params: { clientId: this.$route.params.id } })
  }
}
