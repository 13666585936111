



















import { Component, Vue } from 'vue-property-decorator'
import ProcurementSelect from '@/modules/procurement/ProcurementSelect.vue';
import ProcurementList from '@/modules/procurement/ProcurementList.vue';
import ProcurementService from '@/services/ProcurementService';
import { IMeta } from '@/shared/interfaces/response.interface';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import { GeneralRoutes } from '@/router/routes/general';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';

@Component({
  components: { LoadingSpinner, ProcurementList, ProcurementSelect }
})
export default class ProcurementSearch extends Vue {
  procurementItems: ProcurementListItemData[] = []
  procurementsMeta: IMeta | null = null
  loading: boolean = true

  created(): void{
    if (this.$route.query.categories) {
      this.getItems(String(this.$route.query.categories))
      return
    }
    this.loading = false
  }

  private getProcurementItems(categories: string[]): void {
    const categoryQuery = categories.join(',')
    this.$router.push({
      name: GeneralRoutes.procurementSearch,
      query: { categories: categoryQuery }
    })
    this.getItems(categoryQuery)
  }

  private changePage(page: number = 1) {
    this.getItems(String(this.$route.query.categories), page)
  }

  private getItems(categories: string, page: number = 1) {
    this.loading = true
    ProcurementService.getItemsByCategory(categories, page).then((response) => {
      this.procurementItems = response.data
      this.procurementsMeta = response.meta
      this.loading = false
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }
}
