import http from '@/shared/helpers/http';
import RequestItem from '@/modules/requests/models/request.model';
import IRequestPayload from '@/modules/requests/interfaces/request-payload.interface';

export default class RequestService {
  static async getRequest(id: string): Promise<RequestItem> {
    return http.get(`requests/${id}`).then(response => response.data.data)
  }

  static async createRequest(data: IRequestPayload): Promise<RequestItem> {
    return http.post('requests', data).then(response => response.data.data)
  }

  static async updateRequest(data: IRequestPayload, id: string): Promise<RequestItem> {
    return http.put(`requests/${id}`, data).then(response => response.data.data)
  }

  static async updateSequence(sequence: number, id: number): Promise<RequestItem> {
    return http.put(`requests/${id}/sequence`, { sequence }).then(response => response.data.data)
  }
}
