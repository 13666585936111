import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IPaymentMethodResponse from '@/modules/settings/interfaces/payment-method-response.interface'

export default class PaymentMethod extends Model<IModelResponse> {
  static ALIAS: string = 'payment-method'

  title!: string

  transform(data: IPaymentMethodResponse): void {
    super.transform(data)

    this.title = data.title
    this.id = data.id
  }
}
