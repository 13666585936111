import Field from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { ILoadItemsProps } from '@/shared/interfaces/form/form.interface'

export default class SearchableField extends Field {
  type: FieldTypes = FieldTypes.searchable
  lazy: boolean = false
  displayKey: string = 'title'
  indexKey: string = 'id'
  valueKey: string = this.indexKey
  selectKey: string = 'value'
  detailsKey: string | null = null
  checkForValue: boolean = false
  allowToCreate: boolean = false
  loadItemsProps!: ILoadItemsProps
  loadItemSort?: string
  isObject: boolean = false
  load: boolean = false
  dataOnChange: (form: any, index: number, sourceData: any, group?: any, loading?: boolean) => any = () => false
  noUuid: boolean = false
  vuexGetter!: string
  alternativeGetEndpoint!: string | (() => string)
  activeItemsOnly!: boolean
  items!: Object[]
  multiple: boolean = false
  internalSearch: boolean = false

  setDataOnChange(dataOnChange: (form: any, index: number, response: any, group?: any, loading?: boolean) => any): this {
    this.dataOnChange = dataOnChange
    return this
  }

  setVuexGetter(vuexGetter: string): this {
    this.vuexGetter = vuexGetter
    return this
  }

  setDetailsKey(detailsKey: string): this {
    this.detailsKey = detailsKey
    return this
  }

  loadItems(loadItemsProps: ILoadItemsProps): this {
    this.loadItemsProps = loadItemsProps
    return this
  }

  setSelectKey(selectKey: string): this {
    this.selectKey = selectKey
    return this
  }

  setDisplayKey(displayKey: string): this {
    this.displayKey = displayKey
    return this
  }

  setAlternativeGetEndpoint(endpoint: string | (() => string)): this {
    this.alternativeGetEndpoint = endpoint
    return this
  }

  setIndexKey(indexKey: string): this {
    this.indexKey = indexKey
    return this
  }

  setValueKey(valueKey: string): this {
    this.valueKey = valueKey
    return this
  }

  setLazy(lazy: boolean): this {
    this.lazy = lazy
    return this
  }

  setCheckForValue(checkForValue: boolean): this {
    this.checkForValue = checkForValue
    return this
  }

  setAllowToCreate(allowToCreate: boolean): this {
    this.allowToCreate = allowToCreate
    return this
  }

  setIsObject(isObject: boolean): this {
    this.isObject = isObject
    return this
  }

  setLoad(load: boolean): this {
    this.load = load
    return this
  }

  setNoUuid(noUuid: boolean = true): this {
    this.noUuid = noUuid
    return this
  }

  setActiveItemsOnly(activeItemsOnly: boolean = false): this {
    this.activeItemsOnly = activeItemsOnly
    return this
  }

  setType(type:FieldTypes = FieldTypes.searchable): this {
    this.type = type
    return this
  }

  setMultiple(multiple: boolean = true): this {
    this.multiple = multiple
    return this
  }

  setLoadItemSort(loadItemSort: string): this {
    this.loadItemSort = loadItemSort
    return this
  }

  setInternalSearch(internalSearch: boolean): this {
    this.internalSearch = internalSearch
    return this
  }
}
