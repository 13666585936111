









































import { Component, Prop, Vue } from 'vue-property-decorator'
import Client from '@/modules/client/models/client.model'
import __ from '@/helpers/__'
import ClientDelete from '@/modules/client/ClientDelete.vue';

@Component({
  methods: { __ }
})
export default class ClientContacts extends Vue {
  @Prop() client!: Client

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: ClientDelete,
      id: this.$route.params.id,
      props: {
        title: __('views.clients.index.delete'),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          console.log()
        }
      }
    })
  }
}
