import { render, staticRenderFns } from "./ReminderTemplatesIndex.vue?vue&type=template&id=191002ef&"
import script from "./ReminderTemplatesIndex.vue?vue&type=script&lang=ts&"
export * from "./ReminderTemplatesIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports