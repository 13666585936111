import UserService from '@/services/UserService';

enum tableID {
    dashboardRequests = 'dashboardRequests',
    dashboardRequestsActive = 'dashboardRequestsActive',
    dashboardInvoices = 'dashboardInvoices',
    clientsIndex = 'clientsIndex',
    clientInvoices = 'clientInvoices',
    clientPrepaidInvoices = 'clientPrepaidInvoices',
    clientPayments = 'clientPayments',
    clientDocuments = 'clientDocuments',
    clientDocumentsCustom = 'clientDocumentsCustom',
    clientOffers = 'clientOffers',
    clientRequests = 'clientRequests',
    clientReminders = 'clientReminders',
    requestsIndex = 'requestsIndex',
    invoicesIndex = 'invoicesIndex',
    prepaidInvoicesIndex = 'prepaidInvoicesIndex',
    templateInvoicesIndex = 'templateInvoicesIndex',
    offersIndex = 'offersIndex',
    offersTemplates = 'offersTemplates',
    documentsIndex = 'documentsIndex',
    documentTemplates = 'documentTemplates',
    reminderTemplates = 'reminderTemplates',
    paymentsIndex = 'paymentsIndex',
    servicesIndex = 'servicesIndex',
    usersIndex = 'usersIndex',
    workersIndex = 'workersIndex',
    procurementCompanies = 'procurementCompanies'
}

function getRowCount(id: string) {
  return UserService.getTableRowCount(id)
}

function setRowCount(id: string, number: number) {
  UserService.setTableRowCount(id, number)
}

export const tableCount = {
  tableID,
  getRowCount,
  setRowCount
}
