import http from '@/shared/helpers/http';
import IProjectResponse, { IProjectPayload } from '@/modules/project/interfaces/project-response.interface';

export default class ProjectService {
  static async getProject(id: string): Promise<IProjectResponse> {
    return http.get(`projects/${id}`).then(response => response.data.data)
  }

  static async createProject(data: IProjectPayload): Promise<IProjectResponse> {
    return http.post('projects', data).then(response => response.data.data)
  }

  static async updateProject(data: IProjectPayload, id: string): Promise<IProjectResponse> {
    return http.put(`projects/${id}`, data).then(response => response.data.data)
  }

  static async getProjectOptions(): Promise<IProjectResponse> {
    return http.get('projects/options').then(response => response.data.data)
  }
}
