








































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import DocumentTemplate from './models/document-template.model'
import { GeneralRoutes } from '@/router/routes/general'
import { FieldSizes } from '@/shared/classes/components/form/field'
import DocumentTemplateService from '@/services/DocumentTemplateService';
import DocumentTemplatesDelete from '@/modules/documents/DocumentTemplatesDelete.vue';

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DocumentTemplatesInner extends Vue {
  data: DocumentTemplate = new DocumentTemplate()
  FieldSizes = FieldSizes
  fileLoading: boolean = false
  GeneralRoutes = GeneralRoutes
  docLoading: boolean = false
  pdfLoading: boolean = false

  async created(): Promise<void> {
    http.get(`document-templates/${this.$route.params.id}`).then(response => {
      this.data = new DocumentTemplate(response.data.data)
      this.$root.$emit('headingChange', {
        heading: __('views.documents.templates.edit.title', { template: this.title })
      })
    })
  }

  async downloadPDF(): Promise<void> {
    this.fileLoading = true
    await DocumentTemplateService
      .getDocumentTemplatePDF(this.$route.params.id)
      .catch(() => { this.openFileErrorModal() })
      .finally(() => { this.fileLoading = false })
  }

  async downloadDOC(): Promise<void> {
    this.fileLoading = true
    await DocumentTemplateService
      .getDocumentTemplateDOC(this.$route.params.id)
      .catch(() => { this.openFileErrorModal() })
      .finally(() => { this.fileLoading = false })
  }

  get title() {
    return this.data.title || ''
  }

  openFileErrorModal(): void {
    this.$store.commit('setModalDialog', {
      id: this.$route.params.id,
      hideFooter: true,
      props: {
        titleClass: 'c-red text-center fs-18-important w-100',
        title: __('views.documents.error'),
        size: 'md',
        hideFooter: true,
        onSuccess: () => {
          console.log()
        }
      }
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: DocumentTemplatesDelete,
      id: this.$route.params.id,
      props: {
        title: __('views.documents.delete.title', { document: this.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.$router.push({ name: GeneralRoutes.documentsTemplatesIndex })
        }
      }
    })
  }
}
