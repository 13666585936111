import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import Vue from 'vue';
import { AxiosResponse } from 'axios';

export default function fetchInvoiceData($context: Vue, redirectType: string, redirectRoute: string): Promise<AxiosResponse> {
  return http
    .get(`/invoices/${$context.$route.params.id}`)
    .then((item: IResponse<IModelResponse>) => {
      if (item.data.data.type === redirectType) {
        $context.$router.replace({ name: redirectRoute, params: { id: $context.$route.params.id } })
      }
      return item
    })
}
