
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class StickyTableHeader extends Vue {
  scrollSyncInitialed: boolean = false

  matchTableColumnWidths() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    const headerTable: HTMLElement | null = tableWrap?.querySelector('.header-table')
    const bodyTable: HTMLElement | null = tableWrap?.querySelector('.body-table')
    const headerCells = headerTable?.querySelectorAll('th')
    const bodyCells = bodyTable?.querySelectorAll('tbody tr:first-child td')
    const bodyHeader = bodyTable?.querySelector('thead')

    if (headerTable && bodyTable && bodyHeader) {
      bodyTable.style.marginTop = `-${bodyHeader.offsetHeight}px`
    }

    if (!headerCells || !bodyCells || headerCells.length !== bodyCells.length) {
      setTimeout(() => {
        this.matchTableColumnWidths()
      }, 100)
      return
    }

    let totalWidth = 0
    bodyCells.forEach((cell: any, index) => {
      totalWidth += cell.offsetWidth
      headerCells[index].style.width = `${cell.offsetWidth}px`
    })
    if (headerTable && bodyTable && bodyHeader) {
      headerTable.style.width = `${totalWidth}px`
    }
  }

  syncScrollPositions() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    const tableWrapper = tableWrap?.querySelector('.table-responsive')
    const headerTableWrapper = tableWrap?.querySelector('.header-table-wrapper')

    if (headerTableWrapper && tableWrapper) {
      headerTableWrapper.scrollLeft = tableWrapper.scrollLeft
    }
  }

  setStickyHeader() {
    const tableWrap = this.$refs.tableWrap as HTMLElement
    this.matchTableColumnWidths()
    window.addEventListener('resize', this.matchTableColumnWidths)
    const tableWrapper = tableWrap?.querySelector('.table-responsive')
    if (tableWrapper && !this.scrollSyncInitialed) {
      this.scrollSyncInitialed = true
      tableWrapper.addEventListener('scroll', this.syncScrollPositions)
    }
  }
}
