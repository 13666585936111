





import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Payment from '@/modules/payment/models/payment.model'
import { GeneralRoutes } from '@/router/routes/general'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import PaymentsEdit from '@/modules/payment/PaymentsEdit.vue';
import refreshTable from '@/shared/helpers/data-table/refresh-table';
import PaymentDelete from '@/modules/payment/PaymentsDelete.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable },
  methods: { __ }
})
export default class ClientPayments extends Vue {
  table: DataTableBase | null = null

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientPayments)
      .setModel(Payment)
      .setEndpoint(`/clients/${this.$route.params.id}/payments`)
      .setRowClickCallback((item:Payment) => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: item.invoice.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('invoice.number')
          .setLabel(__('views.payments.index.table.columns.invoice'))
          .setLink({ link: GeneralRoutes.invoice, linkParam: 'invoice_id' })
          .setPopover('notes'),
        new DataTableHeader().setKey('amount').setLabel(__('views.payments.index.table.columns.total')),
        new DataTableHeader().setKey('date').setLabel(__('views.payments.index.table.columns.date')),
        new DataTableHeader().setKey('notes').setLabel(__('views.payments.index.table.columns.notes'))
      ])
      .setActions([
        new DataTableAction().setIcon('edit').setAction(this.editPayment),
        new DataTableAction().setIcon('trash ml-3').setAction(this.deletePayment)
      ])
  }

  editPayment(item: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentsEdit,
      id: item.item.id,
      details: {
        clientId: item.item.invoice.client_id,
        invoiceId: item.item.invoice_id,
        paymentId: item.item.id
      },
      props: {
        title: __('views.payments.edit.title'),
        onSuccess: () => {
          refreshTable(this.$refs.invoiceTable)
        }
      }
    })
  }

  deletePayment(item: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentDelete,
      id: item.item.id,
      details: {
        clientId: item.item.invoice.client_id,
        invoiceId: item.item.invoice_id,
        paymentId: item.item.id
      },
      props: {
        title: __('views.payments.delete.title', { payment: item.item.date }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.invoiceTable)
        }
      }
    })
  }
}
