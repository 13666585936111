


































































import { Component, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import IRequestItem from '@/modules/requests/interfaces/request-item.interface';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import http from '@/shared/helpers/http';
import IResponse, { IMeta } from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import { tableCount } from '@/helpers/tableCount';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

@Component({
  components: { Pagination, TableLoader, DataTable },
  methods: { __ }
})
export default class DashBoardDocuments extends StickyTableHeader {
  @Prop() heading!: string
  @Prop() filter!: string
  @Prop() tableId!: string
  @Prop() hidePerPage!: boolean

  loading = true
  GeneralRoutes = GeneralRoutes
  inProgressItems: ExpiredDocumentCompact[] = []
  meta: IMeta | undefined | null = null
  perPage = 10
  perPageOptions: SelectOption[] = PerPageOptions

  requestFields: TableField[] = [
    { key: 'title', label: __('views.documents.index.table.columns.title'), sortable: true },
    { key: 'company', label: __('views.documents.index.table.columns.client'), sortable: true },
    { key: 'number', label: __('views.documents.index.table.columns.number'), sortable: true },
    { key: 'expired_at', label: __('views.documents.index.table.columns.expiration'), sortable: true }
  ]

  created(): void {
    tableCount.getRowCount(this.tableId).then((perPage: any) => {
      if (!this.hidePerPage) {
        this.perPage = perPage
      }
      this.fetchExpiredDocuments()
    })
  }

  perPageChange(): void {
    tableCount.setRowCount(this.tableId, this.perPage)
    this.fetchExpiredDocuments()
  }

  fetchExpiredDocuments(page: string | number = 1): void {
    this.loading = true
    http
      .get('/dashboards/expiring-documents', { params: { per_page: this.perPage, page, 'filter[in.status]': this.filter } })
      .then((response: IResponse<IModelResponse>) => {
        this.meta = response.data.meta
        const processedItems: ExpiredDocumentCompact[] = []
        response.data.data.forEach((item: any) => {
          processedItems.push({
            title: String(item.title),
            id: Number(item.id),
            company_id: Number(item.client_id),
            number: String(item.number),
            expired_at: String(item.expired_at),
            company: String(item.client?.company_name || '')
          })
        })
        this.inProgressItems = processedItems
      })
      .finally(() => {
        this.loading = false
        if (this.inProgressItems.length) {
          this.$emit('tableReady')
        }
        setTimeout(() => { this.setStickyHeader() }, 100)
      })
  }

  rowClick(item: IRequestItem): void {
    this.$router.push({ name: GeneralRoutes.document, params: { id: item.id.toString() } })
  }

  get showTableFooter():boolean {
    if (!this.meta) return false
    return this.meta.total > this.perPage
  }
}

interface TableField {
  key: string
  label: string
  sortable: boolean
}

interface ExpiredDocumentCompact {
  title: string,
  id: number,
  company: string,
  company_id: number,
  number: string,
  expired_at: string
}
