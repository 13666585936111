





















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import { Route } from 'vue-router'
import { GeneralRoutes } from '@/router/routes/general'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IMaskResponse from './interfaces/mask-response.interface'
import MaskDelete from '@/modules/settings/MaskDelete.vue';
import Mask from '@/shared/classes/mask';

@Component({
  components: { PageHeader, LoadingSpinner },
  methods: {
    __
  }
})
export default class DocumentMaskIndex extends Vue {
  masks!: any
  loading: boolean = true

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route, oldVal: Route): void {
    if (
      newVal.name === GeneralRoutes.settingsDocuments &&
      oldVal &&
      (oldVal.name === GeneralRoutes.documentsMasksCreate || oldVal.name === GeneralRoutes.documentsMasksDelete)
    ) {
      this.getData()
    }
  }

  async created(): Promise<void> {
    await this.getData()
  }

  async getData() {
    this.loading = true
    await http
      .get('document-number-masks?per_page=999')
      .then((response: IResponse<IMaskResponse>) => {
        this.masks = response.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  openDeleteModal(mask: Mask):void {
    this.$store.commit('setModalDialog', {
      component: MaskDelete,
      id: mask.id,
      props: {
        title: __('views.settings.delete.title', { data: mask.mask }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }
}
