






import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Template from '@/modules/offer-template/models/template.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import TemplatesDelete from '@/modules/offer-template/TemplatesDelete.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class TemplatesIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.offerTemplatesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.offersTemplates)
      .setModel(Template)
      .setEndpoint('/offer-templates')
      .setRowClickCallback((item: Template) => {
        this.$router.push({ name: GeneralRoutes.offerTemplatesEdit, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.templates.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.offerTemplatesEdit, linkParam: 'id' })
      ])
      .setActions([new DataTableAction().setIcon('trash').setAction(this.openDeleteModal)])
  }

  openDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: TemplatesDelete,
      id: row.item.id,
      props: {
        title: __('views.templates.delete.title', { offerTemplate: row.item.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }
}
