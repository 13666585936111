







import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import moment from 'moment'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import { UserGetters } from '@/store/modules/user/user.getters'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import { service } from '@/shared/configs/information-types.config'
import InvoiceTemplate from './models/invoice-template.model'
import { GeneralRoutes } from '@/router/routes/general'
import User from '../user/models/user.model'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceTemplateCreate extends Vue {
  form: FormBase | any = null
  user!: User

  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/invoice-templates')
      .setModel(InvoiceTemplate)
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.invoiceTemplatesIndex })
      })
      .setCancel(() => this.$router.push({ name: GeneralRoutes.invoiceTemplatesIndex }))
      .setFields([
        new SearchableField()
          .setKey('client_id')
          .setLoadItemSort('-created_at')
          .setNotFoundComponent(ClientNotFound)
          .setDisplayKey('company_name')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.client'))
          .setSize(FieldSizes.half)
          .setLazy(true)
          .loadItems({
            endpoint: '/clients',
            value: 'id',
            title: 'company_name'
          }),
        new Field()
          .setType(FieldTypes.date)
          .setSize(FieldSizes.half)
          .setKey('date')
          .setRequiredDecoration(true)
          .setEntryKey('date')
          .setTitle(__('views.invoices.form.date')),
        new ArrayField()
          .setKey('items')
          .setCreateNewRow(true)
          .setShowTotals(true)
          .setEntryKey('items')
          .setFieldsTemplates({ service }),
        new SearchableField()
          .setKey('manager_id')
          .setRequiredDecoration(true)
          .setDisplayKey('full_name')
          .setTitle(__('views.invoices.form.manager'))
          .setLazy(true)
          .setSize(FieldSizes.full)
          .loadItems({
            endpoint: '/users',
            value: 'id',
            title: 'full_name'
          }),
        new Field()
          .setType(FieldTypes.number)
          .setRequiredDecoration(true)
          .setKey('generate_every_months')
          .setTitle(__('views.invoice-templates.form.generate-every-months'))
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.number)
          .setRequiredDecoration(true)
          .setKey('pay_in_days')
          .setTitle(__('views.invoice-templates.form.pay-in-days'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('locale')
          .setTitle(__('views.invoices.form.language'))
          .setType(FieldTypes.checkbox)
          .setSize(FieldSizes.half)
          .setCheckedValue('en')
          .setUncheckedValue('lt'),
        new Field()
          .setKey('active')
          .setTitle(__('views.invoice-templates.form.active'))
          .setCheckedValue(true)
          .setUncheckedValue(false)
          .setType(FieldTypes.checkbox)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('notes')
          .setEntryKey('notes')
          .setTitle(__('views.invoices.form.notes'))
          .setType(FieldTypes.richEditor)
      ])
      .setInitialValues({
        date: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        manager_id: this.user.id
      })
  }
}
