import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IPrepaidInvoiceResponse from '@/modules/prepaid-invoices/interfaces/prepaid-invoice-response.interface'
import moment from 'moment'
import Payment from '@/modules/payment/models/payment.model'
import { InvoiceStatusKeysData } from '@/shared/configs/invoice/invoice.config'

export default class PrepaidInvoice extends Model<IModelResponse> {
  static ALIAS: string = 'prepaid-invoice'

  client!: Object
  clientId!: number
  author!: Object
  manager!: Object
  managerId!: number
  number!: string
  type!: string
  status!: string
  date!: string
  dueDate!: string
  total!: string
  totalWithVat!: string
  totalVat!: string
  leftPay!: string
  payed!: string
  pricePerUnit!: string
  notes!: string
  payments!: Payment[]
  items!: Object[]

  transform(data: IPrepaidInvoiceResponse): void {
    super.transform(data)

    this.client = data.client
    this.clientId = data.client_id
    this.author = data.author
    this.manager = data.manager
    this.managerId = data.manager_id
    this.number = data.number
    this.type = data.type
    this.status = data.status
    this.pricePerUnit = data.total
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.dueDate = moment(data.due_date).format('YYYY-MM-DD')
    this.total = data.total
    this.totalWithVat = data.total_with_vat
    this.totalVat = data.total_vat
    this.payed = data.payed
    this.leftPay = data.left_pay
    this.notes = data.notes
    this.payments = data.payments
    this.items = data.items.map((item: any) => ({
      ...item,
      price_per_unit: item.total
    }))
  }
}

export class PrepaidInvoiceTable extends Model<IModelResponse> {
  static ALIAS: string = 'prepaid-invoice-table'

  client!: Object
  author!: Object
  manager!: Object
  number!: string
  type!: string
  status!: string
  date!: string
  dueDate!: string
  total!: string
  totalWithVat!: string
  totalVat!: string
  leftPay!: string
  payed!: string
  payments!: Payment[]
  notes!: string
  items!: Object[]

  transform(data: IPrepaidInvoiceResponse): void {
    super.transform(data)

    this.client = data.client
    this.author = data.author
    this.manager = data.manager
    this.number = data.number
    this.type = data.type
    this.status = InvoiceStatusKeysData[data.status]
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.dueDate = moment(data.due_date).format('YYYY-MM-DD')
    this.total = data.total
    this.totalWithVat = data.total_with_vat
    this.totalVat = data.total_vat
    this.payed = data.payed
    this.leftPay = data.left_pay
    this.notes = data.notes
    this.payments = data.payments
    this.items = data.items
  }
}
