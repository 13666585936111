
















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import ModalNavigationable from '@/shared/components/modal-route/ModalNavigationable.vue'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import RequestItem from '@/modules/requests/models/request.model';
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { ModalNavigationable },
  methods: { __ }
})
export default class RequestDelete extends Vue implements Submittable {
  request: RequestItem | any = null
  error: string = ''
  dismissCountDown: number = 0

  created(): void {
    http
      .get(`requests/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.request = item.data
      })
  }

  submitDialog(): void {
    http
      .delete(`requests/${this.request.id}`)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.requestIndex })
        this.$root.$emit('formSuccess')
      })
      .catch((error: any) => {
        this.error = error.response.data.errors.delete
        this.dismissCountDown = 10
      })
  }
}
