





import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import { GeneralRoutes } from '@/router/routes/general'
import Service from './models/service.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class ServicesDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  GeneralRoutes = GeneralRoutes
  data: Service | any = null

  submitDialog(): void {
    http
      .delete(`services/${this.itemId}`)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
  }
}
