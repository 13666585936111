import IDocumentTemplateResponse from '@/modules/documents/interfaces/document-template-response.interface'
import DocumentTemplate from '@/modules/documents/models/document-template.model'
import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse, { IData } from '@/shared/interfaces/response.interface'
import fileBuffer from '@/shared/helpers/file-buffer-helper';

export default class DocumentTemplateService {
  static async getPaginatedDocumentTemplates(search: string = '', page: number): Promise<IData<DocumentTemplate[]>> {
    return http
      .get(`document-templates?per_page=10&page=${page}&q=${search}`)
      .then((response: IResponse<IModelResponse>) => {
        return {
          data: response.data.data.map((mask: IDocumentTemplateResponse) => new DocumentTemplate(mask)),
          meta: response.data.meta
        }
      })
  }

  static async getDocumentTemplateByID(id: number): Promise<DocumentTemplate> {
    return http
      .get(`document-templates/${id}`)
      .then((response: IResponse<IDocumentTemplateResponse>) => new DocumentTemplate(response.data.data))
  }

  static async submitDocumentTemplate(data: DocumentTemplate): Promise<void> {
    await http.post('document-templates', data.toPostData())
  }

  static async updateDocumentTemplate(data: DocumentTemplate): Promise<void> {
    await http.put(`document-templates/${data.id}`, data.toPostData())
  }

  static async getDocumentTemplatePDF(id: number | string): Promise<void> {
    return http
      .get(`document-templates/${id}/download/pdf`, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
  }

  static async getDocumentTemplateDOC(id: number | string): Promise<void> {
    return http
      .get(`document-templates/${id}/download/word`, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
  }
}
