



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { Submittable } from '@/shared/interfaces/submittable';
import ProcurementService from '@/services/ProcurementService';

@Component({
  methods: { __ }
})
export default class ProcurementDeleteModal extends Vue implements Submittable {
  @Prop() itemId!: number

  submitDialog(): void {
    ProcurementService.saveProcurement({
      procurement_id: this.itemId,
      is_memorized: false
    }).then(() => {
      this.$root.$emit('formSuccess')
    })
  }
}
