



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import FormBase from '@/shared/classes/components/form/form-base'
import Form from '@/shared/components/form/Form.vue'
import Unit from '@/modules/settings/models/unit.model'
import { GeneralRoutes } from '@/router/routes/general'
import Field from '@/shared/classes/components/form/field'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class UnitCreate extends Vue implements Submittable {
  form: FormBase | any = null

  created(): void {
    this.form = new FormBase()
      .setEndpoint('/units')
      .setModel(Unit)
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.settingsDocuments })
      })
      .setNoSubmitButton(true)
      .setFields([new Field().setKey('title').setTitle(__('views.settings.create.unit-title'))])
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
