export enum UserTypes {
  owner = 'owner',
  user = 'user',
  limited = 'limited'
}

export const UserTypeData: any = {
  [UserTypes.owner]: 'Super-Administratorius',
  [UserTypes.user]: 'Naudotojas',
  [UserTypes.limited]: 'Apribotas naudotojas'
}

export enum UserPositions {
  owner = 'owner',
  admin = 'administrator',
  manager = 'manager',
  projectManager = 'project-manager',
}

export const UserPositionData: any = {
  [UserPositions.owner]: 'Savininkas',
  [UserPositions.admin]: 'Administratorius',
  [UserPositions.manager]: 'Vadybininkas',
  [UserPositions.projectManager]: 'Projektų vadovas'
}

export const UserStatusData: any = {
  true: 'Aktyvus',
  false: 'Neaktyvus',
  0: 'Aktyvus',
  1: 'Neaktyvus'
}
