



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Payment from '@/modules/payment/models/payment.model'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { HttpMethod } from '@/helpers/requests'
import SelectOption from '@/shared/classes/components/form/select-option'
import { PaymentMethodActions } from '@/store/modules/payment-method/payment-method.actions'
import { PaymentMethodGetters } from '@/store/modules/payment-method/payment-method.getters'
import { mapActions, mapGetters } from 'vuex'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { PaymentMethods, PaymentMethodsData } from '@/shared/configs/payment-methods/payment-methods.config'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: {
    __,
    ...mapActions({
      paymentMethodsFetch: PaymentMethodActions.fetch
    })
  },
  computed: {
    ...mapGetters({
      paymentMethods: PaymentMethodGetters.getPaymentMethods
    })
  }
})
export default class PaymentsEdit extends Vue implements Submittable {
  @Prop() itemId!: number
  @Prop() itemDetails!: any
  payment: Payment | any = null
  form: FormBase | any = null
  paymentMethods!: any
  paymentMethodsFetch!: () => void

  async created(): Promise<void> {
    await this.paymentMethodsFetch()

    this.form = new FormBase()
      .setEndpoint(`invoices/${this.itemDetails.invoiceId}/payments`)
      .setUuid(this.itemId.toString())
      .setOnSetEntry((payment: any) => {
        this.payment = payment
      })
      .setMethod(HttpMethod.PUT)
      .setModel(Payment)
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setTitle(__('views.payments.form.type'))
          .setType(FieldTypes.select)
          .setSize(FieldSizes.half)
          .setKey('type')
          .setSelectOptions(
            this.paymentMethods.map((method: any) =>
              new SelectOption().setKey(method.title.toString()).setTitle(method.title)
            )
          ),
        new Field()
          .setKey('date')
          .setTitle(__('views.payments.form.date'))
          .setSize(FieldSizes.half)
          .setType(FieldTypes.date),
        new SearchableField()
          .setVisibleIf((data: any) => data.type === PaymentMethodsData[PaymentMethods.prepaidInvoice])
          .setKey('prepaid_invoice_id')
          .setEntryKey('prepaidInvoiceId')
          .setDisplayKey('number')
          .setValueKey('id')
          .setAlternativeGetEndpoint('invoices')
          .setTitle('Sąskaita')
          .setSize(FieldSizes.full)
          .loadItems({
            endpoint: `invoices/prepaid-invoices-by-client/${this.itemDetails.clientID}`,
            value: 'id',
            title: 'number'
          }),
        new Field()
          .setKey('amount')
          .setTitle(__('views.payments.form.amount'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('full_payment')
          .setEntryKey('fullPayment')
          .setTitle(__('views.payments.form.full-payment'))
          .setSize(FieldSizes.half)
          .setType(FieldTypes.checkbox),
        new Field()
          .setKey('notes')
          .setTitle(__('views.payments.form.notes'))
          .setSize(FieldSizes.full)
      ])
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
