





























import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import User from '@/modules/user/models/user.model'
import { GeneralRoutes } from '@/router/routes/general'
import { HttpMethod } from '@/helpers/requests'
import { UserTypes } from '@/shared/configs/user/user.config'
import { UserActions } from '@/store/modules/user/user.actions'
import { Submittable } from '@/shared/interfaces/submittable';
import { SignatureGetters } from '@/store/modules/signature/signature.getters';
import http from '@/shared/helpers/http';
import { SignatureActions } from '@/store/modules/signature/signature.actions';
import { mapGetters } from 'vuex';

@Component({
  components: { Form },
  computed: {
    ...mapGetters({
      signature: SignatureGetters.getSignature
    })
  },
  methods: { __ }
})
export default class UsersEdit extends Vue implements Submittable {
  form: FormBase | any = null
  user: User | any = null
  error: any = null
  signature!: any
  newSignature: any = null

  get title(): string {
    return this.user ? this.user.fullName : ''
  }

  created(): void {
    this.$store.dispatch(SignatureActions.fetchUser)
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.id)
      .setId(this.$router.currentRoute.params.id)
      .setMethod(HttpMethod.PUT)
      .setEndpoint('/users')
      .setModel(User)
      .setOnSetEntry((user: any) => {
        this.user = user
      })
      .setOnSuccess(() => {
        this.$store.dispatch(UserActions.refreshUser)
        this.$router.push({ name: GeneralRoutes.usersIndex })
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('first_name')
          .setEntryKey('firstName')
          .setRequiredDecoration(true)
          .setTitle(__('views.users.form.first_name'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('last_name')
          .setEntryKey('lastName')
          .setTitle(__('views.users.form.last_name'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('email')
          .setTitle(__('views.users.form.email'))
          .setRequiredDecoration(true)
          .setType(FieldTypes.email)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('phone')
          .setTitle(__('views.users.form.phone'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('personal_code')
          .setTitle(__('views.users.form.personal_code'))
          .setEntryKey('personalCode')
          .setSize(FieldSizes.half),
        new Field()
          .setKey('position')
          .setTitle(__('views.users.form.position'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true),
        new Field().setKey('address').setTitle(__('views.users.form.address'))
      ])
      .setInjectValues({
        type: UserTypes.owner,
        permissions: {
          '*': true
        }
      })
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }

  async uploadSignature(event: File): Promise<void> {
    const formData = new FormData()
    formData.append('type', 'users')
    formData.append('file', event)
    await http
      .post('signs', formData)
      .finally(() => {
        this.$store.dispatch(SignatureActions.fetchUser).then(() => {
          if (this.form) {
            this.form.setLoading(false)
          }
        })
      })
      .catch((error: any) => {
        if (this.form) {
          this.form.catchErrors(error)
          this.form.data.image = null
        }
      })
  }
}
