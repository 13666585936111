







import { Component, Vue, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Template from '@/modules/offer-template/models/template.model'
import { GeneralRoutes } from '@/router/routes/general'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import { changeData, heading, vatlessService } from '@/shared/configs/information-types.config'
import { FieldTypes } from '@/shared/components/form/field-types'
import getAll from '@/shared/configs/vuex/get-all.config'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'

@Component({
  components: { PageHeader, Form, LoadingSpinner },
  methods: { __ }
})
export default class TemplatesCreate extends Vue {
  @Prop() meta!: any
  form: FormBase | any = null

  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/offer-templates')
      .setModel(Template)
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.offerTemplatesIndex })
      })
      .setCancel(() => this.$router.push({ name: GeneralRoutes.offerTemplatesIndex }))
      .setFields([
        new Field()
          .setKey('title')
          .setRequiredDecoration(true)
          .setTitle(__('views.templates.form.title'))
          .setSize(FieldSizes.full),
        new ArrayField()
          .isDraggable()
          .setKey('information')
          .setRepeating(true)
          .setShowTotals(true)
          .setFieldsTemplates({
            heading,
            service: vatlessService
          }),
        new Field()
          .setKey('notes')
          .setTitle('Pastabos')
          .setType(FieldTypes.richEditor)
      ])
      .setChangeDataBeforeSubmit(changeData)
  }
}
