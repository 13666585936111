



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import FormBase from '@/shared/classes/components/form/form-base'
import Form from '@/shared/components/form/Form.vue'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import DocumentMask from '@/modules/settings/models/mask.model'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field';
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class MaskCreate extends Vue implements Submittable {
  form: FormBase | any = null

  created(): void {
    this.form = new FormBase()
      .setEndpoint('document-number-masks')
      .setModel(DocumentMask)
      .setNoSubmitButton(true)
      .setFields([
        new Field().setKey('title').setTitle(__('views.settings.create.mask-title')),
        new Field().setKey('mask').setTitle(__('views.settings.create.mask-code')),
        new SearchableField()
          .setKey('document_group')
          .setValueKey('key')
          // .setSelectKey('key')
          .setRequiredDecoration(true)
          .setDisplayKey('value')
          .setTitle(__('views.settings.create.document-group'))
          .setLazy(true)
          .setSize(FieldSizes.full)
          .loadItems({
            endpoint: '/document-groups',
            value: 'key',
            title: 'document_group'
          })
      ])
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
