


























import { Component, Prop, Vue } from 'vue-property-decorator'
import IRequestResponse from '@/modules/requests/interfaces/request-response.interface';
import { Draggable } from 'vue-dndrop';
import { GeneralRoutes } from '@/router/routes/general'

@Component({
  components: {
    Draggable
  }
})
export default class RequestInner extends Vue {
  @Prop() request!: IRequestResponse

  GeneralRoutes = GeneralRoutes

  get phone(): string {
    const { client } = this.request
    if (client) {
      return client.phone
    }
    return this.request.phone
  }

  get email(): string {
    const { client } = this.request
    if (client) {
      return client.company_email
    }
    return this.request.email
  }

  get managerInitials(): null | string {
    const { manager } = this.request
    if (typeof manager !== 'undefined' && manager !== null) {
      return manager.first_name.charAt(0) + manager.last_name.charAt(0)
    }
    return null
  }

  private stripTags(htmlContent: string): string {
    return htmlContent
      .replace(/(<([^>]+)>)/gi, '').replace(new RegExp('&nbsp;', 'g'), ' ')
      .replace(new RegExp('&scaron;', 'g'), 'š')
      .replace(new RegExp('&Scaron;', 'g'), 'Š')
  }
}
