import Field from '@/shared/classes/components/form/field';
import { FieldTypes } from '@/shared/components/form/field-types';

export default class CheckboxField extends Field {
    type: FieldTypes = FieldTypes.checkboxBlock
    multiple: boolean = false

    setMultiple(multiple: boolean = true): this {
      this.multiple = multiple
      return this
    }
}
