





































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue';
import __ from '@/helpers/__';
import DataTable from '@/shared/components/data-table/DataTable.vue';
import { Container, Draggable } from 'vue-dndrop';
import IRequestResponse, { IRequestStatus } from '@/modules/requests/interfaces/request-response.interface';
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import _ from 'lodash';
import RequestCard from '@/modules/requests/RequestCard.vue';
import { GeneralRoutes } from '@/router/routes/general';

interface DropResult {
  removedIndex: number
  addedIndex: number
  payload: any
}

@Component({
  components: {
    RequestCard,
    DataTable,
    PageHeader,
    Container,
    Draggable
  },
  methods: { __ }
})
export default class RequestByStatus extends Vue {
  private statuses: IRequestStatus[] = []
  private requests: { [index: string]: IRequestResponse[] } = {}
  private dropPlaceholderOptions = {
    className: 'drop-preview',
    animationDuration: '150',
    showOnTop: true
  }

  created(): void {
    this.getData()
  }

  private getData(): void {
    http
      .get('/requests/statuses')
      .then((response: IResponse<IModelResponse>) => {
        this.statuses = response.data.data
      })
      .then(() => {
        http
          .get('/requests?per_page=9999&sort=sequence')
          .then((response: IResponse<IModelResponse>) => {
            const orderedRequests: { [index: string]: IRequestResponse[] } = {}
            response.data.data.forEach((requestItem: IRequestResponse) => {
              if (!_.has(orderedRequests, requestItem.status)) {
                orderedRequests[requestItem.status] = []
              }
              orderedRequests[requestItem.status].push(requestItem)
            })
            this.requests = orderedRequests
          })
      })
  }

  getCardPayload(statusIndex: number): any {
    return (index: number) => {
      const statusKey = this.statuses[statusIndex].key
      return this.requests[statusKey][index];
    };
  }

  handleDrop(statusKey: string, dropResult: DropResult) {
    const updatedRequests = _.cloneDeep(this.requests)
    if (dropResult.removedIndex !== null) {
      updatedRequests[statusKey].splice(dropResult.removedIndex, 1)
    }
    if (dropResult.addedIndex !== null) {
      if (!_.has(updatedRequests, statusKey)) {
        updatedRequests[statusKey] = []
      }
      dropResult.payload.status = statusKey
      const requestPayload: IRequestPayload = {
        client_name: dropResult.payload.client_name,
        description: dropResult.payload.description,
        email: dropResult.payload.email,
        phone: dropResult.payload.phone,
        source: dropResult.payload.source,
        status: statusKey,
        send_reminder: dropResult.payload.send_reminder
      }
      if (dropResult.payload.client_id !== null) {
        requestPayload.client_id = dropResult.payload.client_id
      }
      http.put(`requests/${dropResult.payload.id}`, requestPayload)
      updatedRequests[statusKey].splice(dropResult.addedIndex, 0, dropResult.payload)
    }
    this.requests = updatedRequests
  }

  createNew(): void {
    this.$router.push({ name: GeneralRoutes.requestCreate })
  }
}

interface IRequestPayload {
  client_name?: string,
  client_id?: number,
  description: string,
  email: string,
  phone: string,
  source: string,
  status: string
  send_reminder: boolean
}
