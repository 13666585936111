









































































































































































import { Component, Vue } from 'vue-property-decorator'
import { GeneralRoutes } from '@/router/routes/general'
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import IRequestResponse, {
  IRequestStatus
} from '@/modules/requests/interfaces/request-response.interface';
import __ from '@/helpers/__';
import MultiSelect from 'vue-multiselect';
import moment from 'moment';
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base';
import { FieldTypes } from '@/shared/components/form/field-types';
import Field, { FieldSizes } from '@/shared/classes/components/form/field';
import RequestEdit from '@/modules/requests/RequestEdit.vue';
import RequestDelete from '@/modules/requests/RequestDelete.vue';

@Component({
  components: { MultiSelect, Form },
  methods: { __ }
})
export default class RequestInner extends Vue {
  $refs!: {
    comments: HTMLElement
  }

  GeneralRoutes = GeneralRoutes

  private request: IRequestResponse | null = null
  private loading: boolean = true
  private statuses: IRequestStatus[] | null = null
  private form: FormBase | null = null

  mounted(): void {
    this.getData()
    this.getStatuses()
    this.form = new FormBase()
      .setEndpoint(`/requests/${this.$route.params.id}/comments`)
      .setOnSuccess((response: any) => {
        this.updateComments(response)
      })
      .setFields([
        new Field()
          .setType(FieldTypes.textarea)
          .setPlaceholder(__('views.requests.form.comment'))
          .setSize(FieldSizes.full)
          .setKey('comment')
      ])
  }

  private getData(): void {
    this.loading = true
    http
      .get(`/requests/${this.$route.params.id}`)
      .then((item: IResponse<IModelResponse>) => {
        this.request = item.data.data
        this.$root.$emit('headingChange', {
          heading: __('views.requests.index.inner', { client: this.request?.client_name })
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getStatuses(): void {
    http
      .get('/requests/statuses')
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.statuses = item.data
      })
  }

  private formatDate(date: string, format: string = 'yyyy-MM-DD'): string {
    return moment(date).format(format)
  }

  private getInitials(name: string, surname: string): string {
    return name.substring(0, 1) + surname.substring(0, 1)
  }

  private updateStatus($event: any): void {
    http
      .put(`/requests/${this.$route.params.id}/status`, { status: $event.key })
      .then(() => {
        if (this.request !== null) {
          this.request.status_title = $event.value
        }
      })
  }

  private updateComments(response: any): void {
    if (this.form !== null) {
      this.form.data.comment = ''
    }
    if (this.request !== null && typeof this.request.comments !== 'undefined') {
      this.request.comments.push(response.data)
      this.$refs.comments.scrollIntoView({ behavior: 'smooth' })
    }
  }

  openEditModal(): void {
    this.$store.commit('setModalDialog', {
      component: RequestEdit,
      props: {
        title: __('views.requests.form.edit'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: RequestDelete,
      id: this.request?.id,
      props: {
        title: __('views.requests.delete.heading'),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }
}

