import IClientResponse from '@/modules/client/interfaces/client-response.interface'
import Client from '@/modules/client/models/client.model'
import http from '@/shared/helpers/http'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IResponse, { IData } from '@/shared/interfaces/response.interface'

export default class ClientService {
  static async getPaginatedClients(search: string = '', page: number): Promise<IData<Client[]>> {
    return http
      .get(`clients?per_page=10&page=${page}&q=${search}`)
      .then((response: IResponse<IModelResponse>) => {
        return {
          data: response.data.data.map((mask: IClientResponse) => new Client(mask)),
          meta: response.data.meta
        }
      })
  }

  static async getClientByID(id: number): Promise<Client> {
    return http
      .get(`clients/${id}`)
      .then((response: IResponse<IClientResponse>) => new Client(response.data.data))
  }
}
