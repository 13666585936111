import _ from 'lodash'
import IDefaultKeys from '../interfaces/default-keys.interface'

export default class DefaultKeys {
  static ALIAS: string = 'default-keys'

  client?: string = '1'
  date?: string = '1'
  manager?: string = '1'
  documentNumber?: string = '1'
  user?: string = '1'
  offer?: string = '1'
  employee?: string = '1'
  expiredAt?: string = '1'

  constructor(data: IDefaultKeys = {}) {
    _.each(data, (value: any, field: string) => {
      // @ts-ignore
      this[_.camelCase(field)] = value
    })
  }

  toPostData(): IDefaultKeys {
    const data: IDefaultKeys = {}
    _.each(this, (value: any, field: string) => {
      // @ts-ignore
      data[field] = value
    })
    return data
  }
}
