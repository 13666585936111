import http from '@/shared/helpers/http'
import { AxiosResponse } from 'axios'

export default class AuthService {
  static async get(id: string | number): Promise<AxiosResponse> {
    return http.get(`reminder-templates/${id}`).then((response: any) => response.data.data)
  }

  static async edit(id: string | number, data: any): Promise<AxiosResponse> {
    return http.put(`reminder-templates/${id}`, data)
  }
}
