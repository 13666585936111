import _ from 'lodash'
import ICustomKey from '../interfaces/custom-keys.interface'
import IDocumentTemplateResponse from '../interfaces/document-template-response.interface'
import CustomKey from './custom-keys.model'
import DefaultKeys from './default-keys.model'
import moment from 'moment/moment';

export default class DocumentTemplate {
  static ALIAS: string = 'document-template'

  id?: number
  title?: string = ''
  content?: string = ''
  defaultKeys?: DefaultKeys = new DefaultKeys()
  keys?: CustomKey[] = []
  documentNumberMaskId?: number
  expired_at?: string = moment().format('YYYY-MM-DD')

  constructor(data: IDocumentTemplateResponse = {}) {
    if (!data) return
    _.each(data, (value: any, field: string) => {
      if (['keys', 'default_keys'].includes(field)) return
      // @ts-ignore
      this[_.camelCase(field)] = value
    })

    if (data.keys) this.keys = data.keys?.map((key: ICustomKey) => new CustomKey(key))
    if (data.default_keys) this.defaultKeys = new DefaultKeys(data.default_keys)
  }

  toPostData(): IDocumentTemplateResponse {
    const data: IDocumentTemplateResponse = {}
    _.each(this, (value: any, field: string) => {
      // @ts-ignore
      data[_.snakeCase(field)] = value
    })

    if (data.keys) data.keys = data.keys.filter((key: any) => !!key.title)

    return data
  }
}
