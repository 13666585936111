import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IClientUploadResponse from '@/modules/client/interfaces/client-upload-response.interface';

export default class ClientUpload extends Model<IModelResponse> {
  static ALIAS: string = 'client-upload'

  number!: string
  title!: string
  id!: number
  uploaded_date!: string
  client_id!: number
  file_id!: number

  transform(data: IClientUploadResponse): void {
    super.transform(data)

    this.number = data.number
    this.title = data.title
    this.id = data.id
    this.uploaded_date = data.uploaded_date.split(' ')[0]
    this.client_id = data.client_id
    this.file_id = data.files[0].id
  }
}
