import _ from 'lodash'
import ICustomKey from '../interfaces/custom-keys.interface'

export default class CustomKey {
  value: string = ''
  key: string = ''
  title: string = ''

  constructor(data: ICustomKey = {}) {
    _.each(data, (value: any, field: string) => {
      // @ts-ignore
      this[field] = value
    })
  }

  convertTitleToKey(): void {
    this.key = this.title
      .replace(/\s*$/, '')
      .replace(/ /g, '_')
      .toLowerCase()
  }

  get convertedKey(): string {
    return this.title
      .replace(/\s*$/, '')
      .replace(/ /g, '_')
      .toLowerCase()
  }
}
