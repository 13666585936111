import SelectOption from '@/shared/classes/components/form/select-option';

export const PerPageOptions: SelectOption[] = [
  new SelectOption().setKey(10).setTitle('10'),
  new SelectOption().setKey(20).setTitle('20'),
  new SelectOption().setKey(30).setTitle('30'),
  new SelectOption().setKey(50).setTitle('50'),
  new SelectOption().setKey(100).setTitle('100'),
  new SelectOption().setKey(999).setTitle('999')
]
