import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IUnitResponse from '@/modules/settings/interfaces/unit-response.interface'

export default class Unit extends Model<IModelResponse> {
  static ALIAS: string = 'unit'

  title!: string

  transform(data: IUnitResponse): void {
    super.transform(data)

    this.title = data.title
    this.id = data.id
  }
}
