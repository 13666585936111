import __ from '@/helpers/__'

enum InvoiceTemplateTypes {
  active = '1',
  inactive = '0'
}

export const InvoiceTemplateTypeData: any = {
  [InvoiceTemplateTypes.active]: __('common-names.active'),
  [InvoiceTemplateTypes.inactive]: __('common-names.inactive')
}

export default InvoiceTemplateTypes
