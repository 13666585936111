



import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ClientReminderModal extends Vue {
  @Prop() itemId!: number
  @Prop() itemDetails!: any
}
