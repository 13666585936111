





























































































import { Component, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DocumentClass from './models/document.model'
import DocumentService from '@/services/DocumentService'
import { GeneralRoutes } from '@/router/routes/general'
import _ from 'lodash'
import { Route } from 'vue-router'
import DocumentFileDelete from '@/modules/documents/DocumentFileDelete.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';
import PdfPreviewModal from '@/shared/components/PdfPreviewModal.vue';

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}

@Component({
  components: { PdfPreviewModal, TableLoader, PageHeader },
  methods: { __ }
})
export default class DocumentPage extends StickyTableHeader {
  document: DocumentClass = new DocumentClass()
  GeneralRoutes = GeneralRoutes
  loading: boolean = true
  docLoading: boolean = false
  pdfLoading: boolean = false
  documentFile: File[] = []

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.document) {
      this.getDocument()
    }
  }

  fields: fieldType[] = [
    { key: 'name', label: __('views.invoices.table.title'), sortable: true },
    { key: 'action', label: '', sortable: false }
  ]

  created(): void {
    this.getDocument()
  }

  async setFile(event: any): Promise<void> {
    await DocumentService.uploadFiles(this.fileFormData(event.target.files), this.$route.params.id).then(() => {
      this.getDocument()
    })
  }

  async getDocument(): Promise<void> {
    this.document = await DocumentService.getDocument(this.$route.params.id)
    this.$root.$emit('headingChange', {
      heading: __('views.documents.inner.title', { document: this.title })
    })
    this.loading = false
    this.setStickyHeader()
  }

  async downloadPDF(): Promise<void> {
    this.pdfLoading = true
    await DocumentService
      .getDocumentPDF(this.$route.params.id)
      .catch(() => { this.openFileErrorModal() })
      .finally(() => { this.pdfLoading = false })
  }

  async downloadDOC(): Promise<void> {
    this.docLoading = true
    await DocumentService
      .getDocumentDOC(this.$route.params.id)
      .catch(() => {
        this.openFileErrorModal()
      })
      .finally(() => {
        this.docLoading = false
      })
  }

  async downloadFile(data: IFileData): Promise<void> {
    await DocumentService.getDocumentFile(data.id, `${data.name}.${data.type}`)
  }

  openRemoveFileModal(data: any): void {
    this.$store.commit('setModalDialog', {
      component: DocumentFileDelete,
      id: data.item.id,
      props: {
        title: __('views.documents.delete.title', { document: data.item.name }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getDocument()
        }
      }
    })
  }

  get title(): string {
    return this.document.number || ''
  }

  fileFormData(items: File[]): FormData {
    const formData: FormData = new FormData()

    _.each(items, (file: File, index: number) => formData.append(`files[${index}][file]`, file))

    return formData
  }

  openFileErrorModal(): void {
    this.$store.commit('setModalDialog', {
      id: this.$route.params.id,
      hideFooter: true,
      props: {
        titleClass: 'c-red text-center fs-18-important w-100',
        title: __('views.documents.error'),
        size: 'md',
        hideFooter: true,
        onSuccess: () => {
          console.log()
        }
      }
    })
  }
}

interface IFileData {
  id: string
  name: string
  type: string
}
