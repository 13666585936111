import Field from '@/shared/classes/components/form/field';

export default class SelectField extends Field {
    multiple: boolean = false

    setMultiple(multiple: boolean = true): this {
      this.multiple = multiple
      return this
    }
}
