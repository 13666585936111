




























































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import ProcurementCompany from '@/modules/offer/models/procurement-company.model';
import ProcurementService from '@/services/ProcurementService';
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import { IMeta } from '@/shared/interfaces/response.interface';
import ProcurementListInner from '@/modules/procurement/ProcurementListInner.vue';

@Component({
  components: { ProcurementListInner, LoadingSpinner },
  methods: { __ }
})
export default class ProcurementCompanyInner extends Vue {
  loading: boolean = true
  data: ProcurementCompany | null = null
  procurementData: ProcurementListItemData[] | null = null
  procurementMeta: IMeta | null = null
  isMemorizing: boolean = false
  isSaving: boolean = false
  editComment: boolean = false
  comment: string = ''

  getData() {
    ProcurementService.getProcurementCompany(Number(this.$route.params.id)).then(response => {
      this.data = new ProcurementCompany(response.data)
      this.$root.$emit('headingChange', {
        heading: this.data.title || '-'
      })
      this.loading = false
      this.getProcurements()
    })
  }

  getProcurements() {
    if (!this.data) {
      return
    }
    this.isMemorizing = true
    ProcurementService.getProcurementCompanyProcurements(this.data.id, Number(this.$route.query.page || 1)).then(response => {
      this.procurementData = response.data
      this.procurementMeta = response.meta
    }).finally(() => {
      this.isMemorizing = false
    })
  }

  toggleSave() {
    if (!this.data) {
      return
    }
    this.isSaving = true
    ProcurementService.memorizeCompany({
      company_id: this.data.id,
      is_memorized: !(this.data.details?.is_memorized),
      comment: this.data.details?.comment || ''
    }).then(() => {
      if (!this.data) {
        return
      }
      if (!this.data.details) {
        this.data.details = { is_memorized: false, comment: '' }
      }
      if (this.data) {
        this.data.details.is_memorized = !(this.data.details?.is_memorized)
      }
    }).finally(() => {
      this.isSaving = false
    })
  }

  private saveComment(): void {
    if (!this.data) {
      return
    }
    ProcurementService.memorizeCompany({
      company_id: this.data.id,
      is_memorized: this.data.details?.is_memorized,
      comment: this.comment
    }).finally(() => {
      this.editComment = false
      if (!this.data) {
        return
      }
      this.data.details = {
        is_memorized: this.data.details?.is_memorized || false,
        comment: this.comment
      }
    })
  }

  private cancelComment(): void {
    this.editComment = false
    this.comment = this.data?.details?.comment || ''
  }

  created() {
    this.getData()
  }
}

