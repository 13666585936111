





















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Client from '@/modules/client/models/client.model'
import __ from '@/helpers/__'

@Component({
  methods: { __ }
})
export default class ClientsInfo extends Vue {
  @Prop() client!: Client

  private getStat(stat: string, roundNumber: boolean = false): string {
    if (typeof this.client === 'undefined' || !this.client?.stats) {
      return '<span class="client-skeleton client-skeleton--total"/>'
    }
    if (roundNumber) {
      return this.client.stats[stat].toFixed(2)
    }
    return this.client.stats[stat]
  }
}
