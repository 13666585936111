import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import { IModalDialog } from '@/store/modules/modalDialog';

export default class DataTableHeader {
  type: DataTableHeaderTypes = DataTableHeaderTypes.text
  key!: string
  entryKey!: string
  defaultValue!: any
  classes!: string
  width!: string
  align!: string
  value!: string
  component!: any
  meta: any = {}
  showCondition: boolean = true
  sortable: boolean = true
  notFilterableColumn: boolean = false
  visible: boolean = true
  label!: string
  link!: Object
  onClick!: Function
  modalData!: IModalDialog
  popover!: string
  functionalClasses: (data: any) => string = () => ''
  customText: ((data: any) => string) | null = null
  hrefLink: ((data: any) => string) | null = null
  hrefTarget: string | null = null

  setType(type: DataTableHeaderTypes): DataTableHeader {
    this.type = type
    return this
  }

  setEntryKey(entryKey: string): DataTableHeader {
    this.entryKey = entryKey
    return this
  }

  setDefaultValue(defaultValue: any): DataTableHeader {
    this.defaultValue = defaultValue
    return this
  }

  setLabel(label: string): DataTableHeader {
    this.label = label
    return this
  }

  setWidth(width: string): DataTableHeader {
    this.width = width
    return this
  }

  setAlign(align: string): DataTableHeader {
    this.align = align
    return this
  }

  setMeta(meta: any): DataTableHeader {
    this.meta = meta
    return this
  }

  setSortable(sortable: boolean): DataTableHeader {
    this.sortable = sortable
    return this
  }

  setClasses(classes: string): DataTableHeader {
    this.classes = classes
    return this
  }

  setKey(value: string): DataTableHeader {
    this.key = value
    this.value = value
    return this
  }

  setVisible(visible: boolean): DataTableHeader {
    this.visible = visible
    return this
  }

  setNotFilterableColumn(notFilterableColumn: boolean): DataTableHeader {
    this.notFilterableColumn = notFilterableColumn
    return this
  }

  setShowCondition(showCondition: boolean): this {
    this.showCondition = showCondition
    return this
  }

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setFunctionalClasses(classes: (data: any) => string): this {
    this.functionalClasses = classes
    return this
  }

  setLink(link: Object): this {
    this.link = link
    return this
  }

  setOnClick(onClick: Function): this {
    this.onClick = onClick
    return this
  }

  setModalData(modalData: IModalDialog): this {
    this.modalData = modalData
    return this
  }

  setPopover(key: string): this {
    this.popover = key
    return this
  }

  setCustomText(customText: (data: any) => string): this {
    this.customText = customText
    return this
  }

  setHrefLink(hrefLink: (data: any) => string): this {
    this.hrefLink = hrefLink
    return this
  }

  setHrefTarget(hrefTarget: string): this {
    this.hrefTarget = hrefTarget || null
    return this
  }
}
