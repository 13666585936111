import Field from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import FormBase from '../../form-base'

export default class DeleteAction extends Field {
  type: FieldTypes = FieldTypes.deleteAction
  action!: (field?: Field, group?: any, form?: FormBase, rowIndex?: number) => void

  setAction(action: (field?: Field, group?: any, form?: FormBase, rowIndex?: number) => void): this {
    this.action = action
    return this
  }
}
