export default class SelectOption {
  key: any = null!
  title: string = null!

  setKey(key: any): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }
}
