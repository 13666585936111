
















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Invoice from '@/modules/invoices/models/invoice.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class InvoiceDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  invoice: Invoice | any = null
  error: string = ''
  dismissCountDown: number = 0

  async created(): Promise<void> {
    await http
      .get(`invoices/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.invoice = item.data
      })
  }

  get title(): void {
    return this.invoice ? this.invoice.number : ''
  }

  submitDialog(): void {
    http
      .delete(`invoices/${this.invoice.id}`)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.invoicesIndex })
        this.$root.$emit('formSuccess')
      })
      .catch((error: any) => {
        this.error = error.response.data.errors.delete
        this.dismissCountDown = 10
      })
  }
}
