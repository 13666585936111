






















import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash';
import ProcurementCategory from '@/modules/procurement/interfaces/procurement-category.interface';
@Component({ name: 'ProcurementSelectItem' })
export default class ProcurementSelectItem extends Vue {
  @Prop() data?: ProcurementCategory
  @Prop() itemStatus?: {[key:string]: number}

  $refs!: any
  collapsed: boolean = true

  created(): void {
    if (this.itemValue === -1) {
      this.collapsed = false
    }

    this.$root.$on('savedCategoryLoad', (code: string) => {
      if (code === this.data?.code) {
        this.onClick(1, code)
      }
    })
  }

  private toggleCollapse():void {
    this.collapsed = !this.collapsed
  }

  public onClick(value: number, ref: string) {
    this.updateData(value, ref)
    this.$emit('updateProcurementSelectionParent', value)
  }

  public updateData(value: number, ref: string): void {
    if (typeof this.data !== 'undefined') {
      this.$root.$emit('updateProcurementSelection', { value, ref })

      if (typeof this.data.children !== 'undefined') {
        for (const item of this.data.children) {
          if (!_.has(this.$refs, item.code)) {
            break
          }
          this.$refs[item.code][0].updateData(value, item.code)
        }
      }
    }
  }

  public swapValue(currentValue: number): number {
    if (currentValue === 0 || currentValue === -1) {
      return 1
    }
    return 0
  }

  public checkChildData(newValue: number) {
    let updateValue = newValue;
    if (typeof this.data !== 'undefined' && typeof this.data.children !== 'undefined') {
      if (newValue !== -1) {
        for (const item of this.data.children) {
          if (typeof this.itemStatus !== 'undefined') {
            if ((this.itemStatus[item.code] || 0) !== newValue) {
              updateValue = -1
              break
            }
          }
        }
      }
      this.$root.$emit('updateProcurementSelection', { value: updateValue, ref: this.itemRef })
      this.$emit('updateProcurementSelectionParent', updateValue)
    }
  }

  get itemValue(): number {
    if (typeof this.itemStatus !== 'undefined') {
      return this.itemStatus[this.itemRef] || 0
    }
    return 0
  }

  get itemRef(): string {
    if (typeof this.data !== 'undefined') {
      return this.data.code
    }
    return ''
  }
}

