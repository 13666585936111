











import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ProcurementSelectLoader extends Vue {
  private items: number = 15
}

