import contentDisposition from 'content-disposition'
import fileDownload from 'js-file-download'

export default class fileBuffer {
  static async downloadFileBuffer(response: any) {
    const parsedDisposition = contentDisposition.parse(response.headers['content-disposition'])
    fileDownload(response.data, parsedDisposition.parameters.filename)
  }

  static async downloadFileBufferWithoutName(response: any, filename: string) {
    fileDownload(response.data, filename)
  }

  static async downloadBlob(response: any, name: string) {
    var blob = new Blob([response.data])
    var downloadElement = document.createElement('a')
    var href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = name
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  }
}
