










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { tableCount } from '@/helpers/tableCount';
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter';
import { FieldTypes } from '@/shared/components/form/field-types';
import SelectOption from '@/shared/classes/components/form/select-option';
import Project from '@/modules/project/models/project.model';
import CheckboxField from '@/shared/classes/components/form/fields/checkbox-field';
import {
  ProjectStatusKeys,
  ProjectStatusKeysData,
  ProjectTypeKeys,
  ProjectTypeKeysData
} from '@/shared/configs/project.config';
import SearchableField from '@/shared/classes/components/form/fields/searchable-field';
import SelectField from '@/shared/classes/components/form/fields/select-field';
import highlightExpiring from '@/shared/helpers/data-table/highlight-expiring';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class ProjectsIndex extends Vue {
  @Prop({ default: false }) showFilter?: boolean
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.projectsIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.offersIndex)
      .setSumCheckboxes(false)
      .setModel(Project)
      .setEndpoint('/projects')
      .setPerPageUnderFilter(true)
      .setRowClassCallback(highlightExpiring)
      .setRowClickCallback((item: Project) => {
        this.$router.push({ name: GeneralRoutes.projectsEdit, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('client.company_name')
          .setLabel(__('views.projects.index.table.columns.client'))
          .setLink({ link: GeneralRoutes.client, linkParam: 'client.id' }),
        new DataTableHeader()
          .setKey('url')
          .setLabel(__('views.projects.index.table.columns.url'))
          .setHrefLink((data: Project) => data.url)
          .setHrefTarget('_blank'),
        new DataTableHeader().setKey('validUntil').setLabel(__('views.projects.index.table.columns.valid-till')),
        new DataTableHeader()
          .setKey('type')
          .setLabel(__('views.projects.index.table.columns.type'))
          .setCustomText((data: Project) => {
            if (data.type === ProjectTypeKeys.contract) return __(ProjectTypeKeysData[ProjectTypeKeys.contract])
            if (data.type === ProjectTypeKeys.warranty) return __(ProjectTypeKeysData[ProjectTypeKeys.warranty])
            if (data.type === ProjectTypeKeys.without_contract) return __(ProjectTypeKeysData[ProjectTypeKeys.without_contract])

            return ''
          }),
        new DataTableHeader()
          .setKey('cmsType')
          .setLabel(__('views.projects.index.table.columns.cms'))
          .setCustomText((data: Project) => {
            if (data.cmsType === 'wordpress') return 'WordPress'
            if (data.cmsType === 'prestashop') return 'Prestashop'
            if (data.cmsType === 'laravel') return 'Lavavel'
            if (data.cmsType === 'other') return 'kita'

            return ''
          }),
        new DataTableHeader().setKey('monthPrice').setLabel(__('views.projects.index.table.columns.price')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.projects.index.table.columns.status'))
          .setFunctionalClasses((data: Project) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: Project) => {
            if (data.status === ProjectStatusKeys.active) return __(ProjectStatusKeysData[ProjectStatusKeys.active])
            if (data.status === ProjectStatusKeys.disabled) return __(ProjectStatusKeysData[ProjectStatusKeys.disabled])

            return ''
          })
      ])

    this.table
      .setFilterAlwaysOpen(true)
      .setSearchAlwaysVisible(true)
      .setFilter([
        new DataTableFilter()
          .setOperator(FilterOperators.in)
          .setField(
            new SearchableField()
              .setNoUuid(true)
              .setInternalSearch(true)
              .setMultiple(true)
              .setDisplayKey('value')
              .setValueKey('key')
              .setSize('col-12 col-md-6 col-lg-3')
              .setTitle(__('views.projects.index.table.columns.cms'))
              .setKey('cms_type')
              .loadItems({
                endpoint: 'projects/options',
                value: 'key',
                title: 'value'
              })
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.in)
          .setField(
            new SelectField()
              .setType(FieldTypes.select)
              .setSize('col-12 col-md-6 col-lg-3')
              .setTitle(__('views.projects.index.table.columns.type'))
              .setKey('type')
              .setSelectOptions([
                new SelectOption().setKey(ProjectTypeKeys.contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.contract]),
                new SelectOption().setKey(ProjectTypeKeys.warranty).setTitle(ProjectTypeKeysData[ProjectTypeKeys.warranty]),
                new SelectOption().setKey(ProjectTypeKeys.without_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.without_contract])
              ])
          ),
        new DataTableFilter().setOperator(FilterOperators.in).setField(
          new CheckboxField()
            .setKey('status')
            .setMultiple(true)
            .setTitle(__('views.projects.index.table.filter.show-by-active'))
            .setSize('col-12 col-md-6 col-lg-3')
            .setType(FieldTypes.checkboxBlock)
            .setSelectOptions([
              new SelectOption()
                .setKey('active')
                .setTitle(__('views.projects.index.table.filter.show-active'))
            ])
        )
      ])
  }

  addNewProject(): void {
    this.$router.push({ name: GeneralRoutes.projectsCreate })
  }
}
