



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import DocumentMask from './models/mask.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class MaskDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  mask: DocumentMask | any = null
  GeneralRoutes = GeneralRoutes

  async created(): Promise<void> {
    await http
      .get(`document-number-masks/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.mask = item.data
      })
  }

  get title(): string {
    return this.mask ? this.mask.mask : ''
  }

  submitDialog(): void {
    http.delete(`document-number-masks/${this.itemId}`).then(() => {
      this.$root.$emit('formSuccess')
    })
  }
}
