import IDocumentResponse from '@/modules/documents/interfaces/document-response.interface'
import DocumentClass from '@/modules/documents/models/document.model'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import http from '@/shared/helpers/http'
import { AxiosResponse } from 'axios'
import IDocumentGroup from '@/modules/documents/interfaces/document-group';

export default class DocumentService {
  static async getNextDocumentNumber(maskId: number): Promise<string> {
    return http.get(`document-templates/${maskId}/next-number`).then(response => response.data.number)
  }

  static async getDocument(id: string): Promise<DocumentClass> {
    return http.get(`documents/${id}`).then(response => new DocumentClass(response.data.data))
  }

  static async getDocumentPDFBase64(id: number | string): Promise<AxiosResponse> {
    return http
      .get(`documents/${id}/download/pdf`, {
        responseType: 'arraybuffer'
      })
  }

  static async getDocumentPDF(id: number | string): Promise<void> {
    return http
      .get(`documents/${id}/download/pdf`, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
  }

  static async getDocumentDOC(id: number | string): Promise<void> {
    return http
      .get(`documents/${id}/download/word`, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
  }

  static async getDocumentFile(id: number | string, title: string): Promise<void> {
    return http
      .get(`files/${id}/download`, {
        responseType: 'blob'
      })
      .then(response => {
        fileBuffer.downloadBlob(response, title)
      })
  }

  static async removeDocumentFile(id: number | string): Promise<void> {
    return http.delete(`files/${id}`)
  }

  static async createDocument(data: IDocumentResponse): Promise<DocumentClass> {
    return http.post('documents', data).then(response => new DocumentClass(response.data.data))
  }

  static async updateDocument(data: IDocumentResponse, id: string): Promise<DocumentClass> {
    return http.put(`documents/${id}`, data).then(response => new DocumentClass(response.data.data))
  }

  static async uploadFiles(data: FormData, id: string): Promise<AxiosResponse> {
    return http.post(`files/store-by-model/documents/${id}`, data)
  }

  static async getDocumentGroups(): Promise<IDocumentGroup[]> {
    return http.get('document-groups').then(response => response.data.data)
  }

  static async getDocumentsByGroup(group: string): Promise<IDocumentGroup[]> {
    return http.get(`documents/by-group/${group}`).then(response => response.data.data)
  }

  static async uploadDocument(data: FormData): Promise<AxiosResponse> {
    return http.post('client-documents', data).then(response => response.data.data)
  }

  static async deleteDocument(id: number): Promise<AxiosResponse> {
    return http.delete(`client-documents/${id}`)
  }

  static async downloadDocument(id: number): Promise<AxiosResponse> {
    return http.get(`files/${id}/download`, {
      responseType: 'blob'
    })
  }
}
