










































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Setting from '@/modules/settings/models/setting.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import SettingResponse from '@/modules/settings/interfaces/setting-response.interface'
import { mapGetters } from 'vuex'
import { LogoGetters } from '@/store/modules/logo/logo.getters'
import { LogoActions } from '@/store/modules/logo/logo.actions'
import { SignatureGetters } from '@/store/modules/signature/signature.getters';
import { SignatureActions } from '@/store/modules/signature/signature.actions';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      logo: LogoGetters.getLogo,
      signature: SignatureGetters.getSignature
    })
  }
})
export default class GeneralSettings extends Vue {
  group: string = 'general'
  logo!: any
  newLogo: any = null
  signature!: any
  newSignature: any = null
  error: any = null

  form: FormBase = new FormBase()
    .setEndpoint(`/settings/${this.group}`)
    .setModel(Setting)
    .setOnSuccess((data: any) => {
      document.title = data.data[0]?.value
        ? `${process.env.VUE_APP_TITLE} | ${data.data[0].value}`
        : process.env.VUE_APP_TITLE
    })
    .setSuccessAlert('Nustatymai išsaugoti')
    .setFields([
      new Field()
        .setKey('0.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.company-name`)),
      new Field()
        .setKey('1.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.address`)),
      new Field()
        .setKey('2.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.company-code`)),
      new Field()
        .setKey('3.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.vat-code`)),
      new Field()
        .setKey('4.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.account`)),
      new Field()
        .setKey('5.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.swift-code`)),
      new Field()
        .setKey('6.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.main-phone`)),
      new Field()
        .setKey('7.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.main-email`)),
      new Field()
        .setKey('8.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.owner`)),
      new Field()
        .setKey('9.value')
        .setSize(FieldSizes.half)
        .setRequiredDecoration(true)
        .setTitle(__(`views.settings.form.${this.group}.main-web`))
    ])
    .setChangeDataBeforeSubmit(this.postData)

  async created(): Promise<void> {
    this.form.setLoading(true)
    this.$store.dispatch(LogoActions.fetch)
    this.$store.dispatch(SignatureActions.fetchMain)
    await http
      .get(`settings/${this.group}`)
      .then((response: IResponse<SettingResponse[]>) => {
        const setting = response.data.data
        setting.forEach((settingValue: any, index: number) => {
          this.form.setData({
            ...this.form.data,
            [index]: {
              value: settingValue.value,
              id: settingValue.id,
              key: settingValue.key
            }
          })
        })
      })
      .finally(() => this.form.setLoading(false))
  }

  async uploadImage(event: File): Promise<void> {
    const formData = new FormData()
    formData.append('file', event)
    await http
      .post('logos', formData)
      .finally(() => {
        this.$store.dispatch(LogoActions.fetch).then(() => {
          this.form.setLoading(false)
        })
      })
      .catch((error: any) => {
        this.form.catchErrors(error)
        this.form.data.image = null
      })
  }

  async uploadSignature(event: File): Promise<void> {
    const formData = new FormData()
    formData.append('type', 'settings')
    formData.append('file', event)
    await http
      .post('signs', formData)
      .finally(() => {
        this.$store.dispatch(SignatureActions.fetchMain).then(() => {
          this.form.setLoading(false)
        })
      })
      .catch((error: any) => {
        this.form.catchErrors(error)
        this.form.data.image = null
      })
  }

  postData(): any {
    const postData: any = { settings: {} }
    Object.keys(this.form.data).forEach((key: string) => {
      if (key === 'image') return
      postData.settings[key] = {
        ...this.form.data[key],
        value: this.form.data[key].value ? this.form.data[key].value : null
      }
    })
    return postData
  }
}
