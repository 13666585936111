import { Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'

export default class AbstractHeader extends Vue {
  @Prop() table!: DataTableBase
  @Prop() header!: DataTableHeader
  @Prop() props!: any

  get value(): any {
    return _.get(this.props.item, this.header.entryKey ? this.header.entryKey : this.header.key)
  }
}
