



import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Payment from '@/modules/payment/models/payment.model'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import moment from 'moment'
import { PaymentMethodActions } from '@/store/modules/payment-method/payment-method.actions'
import { PaymentMethodGetters } from '@/store/modules/payment-method/payment-method.getters'
import { PaymentMethodsData, PaymentMethods } from '@/shared/configs/payment-methods/payment-methods.config'
import SelectOption from '@/shared/classes/components/form/select-option'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: {
    __,
    ...mapActions({
      paymentMethodsFetch: PaymentMethodActions.fetch
    })
  },
  computed: {
    ...mapGetters({
      paymentMethods: PaymentMethodGetters.getPaymentMethods
    })
  }
})
export default class PaymentCreate extends Vue implements Submittable {
  @Prop() invoice!: any
  @Prop() itemId!: number
  @Prop() itemDetails!: any
  paymentMethods!: any
  paymentMethodsFetch!: () => void
  invoicePaymentAmount: number = 0

  @Watch('form.data.amount', { deep: true }) private onAmountChange(newVal: any): void {
    const checkboxField = _.find(this.form.fields, (field: any) => field.key === 'full_payment')
    if (Number(newVal) >= this.itemDetails.totalWithVat) this.form.data.full_payment = checkboxField.checkedValue
    else this.form.data.full_payment = checkboxField.uncheckedValue
  }

  form: FormBase | any = null
  payment: Payment | null = null
  success: boolean = false

  fullPayment(data: any): void {
    if (Boolean(data) && this.form.data.amount < this.itemDetails.totalWithVat) {
      this.form.data.amount = this.itemDetails.leftToPay
    }
  }

  setInvoiceData(response: any) {
    this.invoicePaymentAmount = Number(response.details) || 0
    this.form.data.amount = response.details || null
  }

  async created(): Promise<void> {
    await this.paymentMethodsFetch()

    this.form = new FormBase()
      .setEndpoint(`/invoices/${this.itemId}/payments`)
      .setModel(Payment)
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setTitle(__('views.payments.form.type'))
          .setType(FieldTypes.select)
          .setSize(FieldSizes.half)
          .setKey('type')
          .setSelectOptions(
            this.paymentMethods.map((method: any) =>
              new SelectOption().setKey(method.title.toString()).setTitle(method.title)
            )
          ),
        new Field()
          .setKey('date')
          .setTitle(__('views.payments.form.date'))
          .setSize(FieldSizes.half)
          .setType(FieldTypes.date),
        new SearchableField()
          .setVisibleIf((data: any) => data.type === PaymentMethodsData[PaymentMethods.prepaidInvoice])
          .setKey('prepaid_invoice_id')
          .setDisplayKey('number')
          .setDetailsKey('total_with_vat')
          .setValueKey('id')
          .setTitle('Sąskaita')
          .setSize(FieldSizes.full)
          .setOnChange(this.setInvoiceData)
          .loadItems({
            endpoint: `/invoices/prepaid-invoices-by-client/${this.itemDetails.clientID}`,
            value: 'id',
            title: 'number'
          }),
        new Field()
          .setType(FieldTypes.number)
          .setKey('amount')
          .setTitle(__('views.payments.form.amount'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('full_payment')
          .setTitle(__('views.payments.form.full-payment'))
          .setSize(FieldSizes.half)
          .setType(FieldTypes.checkbox)
          .setOnChange((data: any) => this.fullPayment(data)),
        new Field()
          .setKey('notes')
          .setTitle(__('views.payments.form.notes'))
          .setSize(FieldSizes.full)
      ])
      .setInitialValues({
        date: moment().format('YYYY-MM-DD')
      })
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
