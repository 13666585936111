export enum OfferStatusKeys {
    all = 'all',
    selected = 'selected',
    active = 'active',
    approved = 'approved',
    refused = 'refused',
    suspended = 'suspended'
}

export const InvoiceStatusKeysData: any = {
  [OfferStatusKeys.all]: 'Visas',
  [OfferStatusKeys.selected]: 'Pasirinktas',
  [OfferStatusKeys.active]: 'Aktyvus',
  [OfferStatusKeys.approved]: 'Patvirtintas',
  [OfferStatusKeys.refused]: 'Atsisakytas',
  [OfferStatusKeys.suspended]: 'Sustabdytas'
}

export const InvoiceStatusKeysDataPlural: any = {
  [OfferStatusKeys.all]: 'Visi',
  [OfferStatusKeys.selected]: 'Pasirinkti',
  [OfferStatusKeys.active]: 'Aktyvūs',
  [OfferStatusKeys.approved]: 'Patvirtinti',
  [OfferStatusKeys.refused]: 'Atsisakyti',
  [OfferStatusKeys.suspended]: 'Sustabdyti'
}
