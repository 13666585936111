














import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ProcurementListLoader extends Vue {
 private items:number = 10
}
