










































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import WorkerClass from '@/modules/worker/models/worker.model'
import WorkersEdit from '@/modules/worker/WorkersEdit.vue';

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class WorkerPage extends Vue {
  worker: WorkerClass | any = null

  mounted(): void {
    this.getData()
  }

  async getData(): Promise<void> {
    await http
      .get(`/employees/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.worker = new WorkerClass(item.data)
        this.$root.$emit('headingChange', {
          heading: __('views.workers.info.title', { worker: this.worker.fullName })
        })
      })
  }

  edit(): void {
    this.$router.push({ name: GeneralRoutes.workersEdit })
  }

  getInitials(worker: WorkerClass) : any {
    const workerName = worker.fullName.split(' ')
    let workerInitials = ''
    workerName.forEach(word => (workerInitials += word.substring(0, 1)))
    return workerInitials
  }

  openEditModal():void {
    this.$store.commit('setModalDialog', {
      component: WorkersEdit,
      props: {
        title: __('views.workers.edit.title', { worker: this.worker.fullName }),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }
}
