
















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class ClientDelete extends Vue implements Submittable {
  @Prop() itemId!: number

  error: string = ''
  dismissCountDown: number = 0

  submitDialog(): void {
    http
      .delete(`clients/${this.itemId}`)
      .then(() => {
        this.$root.$emit('formSuccess')
        this.$router.push({ name: GeneralRoutes.clientsIndex })
      })
      .catch((error: any) => {
        this.error = error.response.data.message
        this.dismissCountDown = 10
      })
  }
}
