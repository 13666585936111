



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PrepaidInvoice from '@/modules/prepaid-invoices/models/prepaid-invoice.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class PrepaidInvoiceDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  invoice: PrepaidInvoice | any = null

  async created(): Promise<void> {
    await http
      .get(`invoices/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.invoice = item.data
      })
  }

  get title(): string {
    return this.invoice ? this.invoice.number : ''
  }

  submitDialog(): void {
    http
      .delete(`invoices/${this.invoice.id}`)
      .then(() => {
        this.$root.$emit('formSuccess')
        this.$router.push({ name: GeneralRoutes.prepaidInvoicesIndex })
      })
  }
}
