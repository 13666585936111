




























import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import { Submittable } from '@/shared/interfaces/submittable';
import { FieldSizes } from '@/shared/classes/components/form/field';
import moment from 'moment';
import Form from '@/shared/components/form/Form.vue';
import DocumentService from '@/services/DocumentService';
import { AxiosError } from 'axios';

@Component({
  components: { DataTable, Form },
  methods: { __ }
})
export default class ClientDocumentUpload extends Vue implements Submittable {
  error: any = null
  FieldSizes = FieldSizes
  title: string = ''
  number: string = ''
  file: any = []

  submitDialog(): void {
    const formData = new FormData()
    formData.append('title', this.file.name)
    formData.append('number', this.number)
    formData.append('files[0][file]', this.file)
    formData.append('client_id', this.$router.currentRoute.params.id)
    formData.append('uploaded_date', moment().format('YYYY-MM-DD HH:mm'))
    this.error = {}
    DocumentService.uploadDocument(formData)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
      .catch((error:AxiosError) => {
        this.error = error
      })
  }
}
