






import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import ReminderTemplate from '@/modules/reminder-template/models/reminder-template.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class DefaultTemplatesIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.reminderTemplatesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.reminderTemplates)
      .setModel(ReminderTemplate)
      .setEndpoint('/default-templates')
      .setRowClickCallback((item: ReminderTemplate) => {
        this.$router.push({ name: GeneralRoutes.defaultTemplatesEdit, params: { id: (item.id || '').toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('title')
          .setLabel(__('views.reminder-templates.index.table.columns.title'))
          .setLink({ link: GeneralRoutes.defaultTemplatesEdit, linkParam: 'id' }),
        new DataTableHeader().setKey('createdAt').setLabel(__('views.reminder-templates.index.table.columns.createdAt'))
      ])
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.reminderTemplatesCreate })
  }
}
