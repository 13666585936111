export default class DataTableLogo {
  key!: string
  logo: ((data: any) => string | null) | null | string = null
  initials: ((data: any) => string | null) | null | string = null

  setLogo(logo: ((data: any) => string | null) | null | string): DataTableLogo {
    this.logo = logo
    return this
  }

  setInitials(initials: ((data: any) => string | null) | null | string): DataTableLogo {
    this.initials = initials
    return this
  }

  setKey(key: string): DataTableLogo {
    this.key = key
    return this
  }
}
