






import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DataTableLogo from '@/shared/classes/components/data-table/data-table-logo'
import WorkerClass from './models/worker.model'
import WorkersCreate from '@/modules/worker/WorkersCreate.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class WorkersIndex extends Vue {
  table: DataTableBase | null = null

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.workersIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.$root.$on('openAddWorkerModal', this.openAddNewModal)

    this.table = new DataTableBase()
      .setId(tableCount.tableID.workersIndex)
      .setModel(WorkerClass)
      .setEndpoint('/employees')
      .setRowClickCallback((item: WorkerClass) => {
        this.$router.push({ name: GeneralRoutes.worker, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('fullName')
          .setLabel(__('views.workers.index.table.full_name'))
          .setLink({ link: GeneralRoutes.worker, linkParam: 'id' }),
        new DataTableHeader()
          .setKey('position')
          .setEntryKey('position')
          .setLabel(__('views.workers.index.table.position')),
        new DataTableHeader()
          .setKey('email')
          .setEntryKey('email')
          .setLabel(__('views.workers.index.table.email'))
          .setHrefLink((data: WorkerClass) => `mailto:${data.email}`),
        new DataTableHeader()
          .setKey('phone')
          .setEntryKey('phone')
          .setLabel(__('views.workers.index.table.phone'))
          .setHrefLink((data: WorkerClass) => `tel:${data.phone}`)
      ])
      .setLogo(
        new DataTableLogo()
          .setKey('userLogo')
          .setLogo(() => null)
          .setInitials((data: WorkerClass) => data.fullName.substring(0, 1) || null)
      )
  }

  openAddNewModal(): void {
    this.$store.commit('setModalDialog', {
      component: WorkersCreate,
      props: {
        title: __('views.workers.create.title'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }
}
