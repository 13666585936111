



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import User from '@/modules/user/models/user.model'
import { GeneralRoutes } from '@/router/routes/general'
import { HttpMethod } from '@/helpers/requests'
import WorkerClass from './models/worker.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class WorkersEdit extends Vue implements Submittable {
  form: FormBase | any = null
  worker: WorkerClass | any = null

  get title(): string {
    return this.worker ? this.worker.fullName : ''
  }

  created(): void {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.id)
      .setId(this.$router.currentRoute.params.id)
      .setMethod(HttpMethod.PUT)
      .setEndpoint('/employees')
      .setModel(User)
      .setOnSetEntry((worker: any) => {
        this.worker = worker
      })
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.workersIndex })
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('full_name')
          .setEntryKey('fullName')
          .setTitle(__('views.workers.form.full_name'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('email')
          .setTitle(__('views.workers.form.email'))
          .setType(FieldTypes.email)
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('phone')
          .setTitle(__('views.workers.form.phone'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('personal_code')
          .setEntryKey('personalCode')
          .setTitle(__('views.workers.form.personal_code'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('address')
          .setTitle(__('views.workers.form.address'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('position')
          .setTitle(__('views.workers.form.position'))
          .setSize(FieldSizes.half)
      ])
      .setInjectValues({
        permissions: {
          '*': true
        }
      })
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
