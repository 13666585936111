import DocumentService from '@/services/DocumentService'
import _ from 'lodash'
import moment from 'moment'
import IDocumentResponse from '../interfaces/document-response.interface'
import CustomKey from './custom-keys.model'
import DocumentTemplate from './document-template.model'

export default class DocumentClass {
  id?: number
  title?: string
  documentTemplateId?: number
  clientId?: number
  date?: string = moment().format('YYYY-MM-DD')
  managerId?: number
  number?: string = ''
  keys?: CustomKey[] = []
  content?: string = ''
  generatedContent?: string
  offerId?: number
  employeeId?: number
  expired_at?: string = moment().format('YYYY-MM-DD')
  protected template?: DocumentTemplate
  allowedKeys: Record<string, boolean> = {
    client: true,
    date: true,
    manager: true,
    documentNumber: true,
    user: true,
    offer: true,
    employee: true,
    expiredAt: true
  }

  constructor(data: IDocumentResponse = {}) {
    _.each(data, (value: any, field: string) => {
      // @ts-ignore
      if (value) this[_.camelCase(field)] = value
    })

    _.each(data.default_keys, (defaultKey: any, key: string) => {
      if (defaultKey === '1') this.allowedKeys[key] = true
      else this.allowedKeys[key] = false
    })
  }

  async setTemplate(template: DocumentTemplate): Promise<void> {
    this.content = template.content
    this.template = template
    this.keys = template.keys
    _.each(template.defaultKeys, (defaultKey: any, key: string) => {
      if (defaultKey === '1') this.allowedKeys[key] = true
      else this.allowedKeys[key] = false
    })
    if (template.id) this.number = await DocumentService.getNextDocumentNumber(template.id)
  }

  convertToSnakeCase(): IDocumentResponse {
    const data = {}
    _.each(this, (value: any, key: string) => {
      if (['template', 'customKeys', 'allowedKeys', 'generatedContent'].includes(key)) return
      // @ts-ignore
      if (value) data[_.snakeCase(key)] = value
    })
    return data
  }
}
