



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Client from '@/modules/client/models/client.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class ClientsCreate extends Vue implements Submittable {
  @Prop() itemDetails!: any

  form: FormBase = new FormBase()
    .setId(this.$router.currentRoute.params.id)
    .setEndpoint('/clients')
    .setModel(Client)
    .setNoSubmitButton(true)
    .setFields([
      new Field()
        .setKey('company_name')
        .setTitle(__('views.clients.form.company_name'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_address')
        .setTitle(__('views.clients.form.company_address'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_code')
        .setTitle(__('views.clients.form.company_code'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_vat_code')
        .setTitle(__('views.clients.form.company_vat_code'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('company_director')
        .setTitle(__('views.clients.form.director'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('contact_full_name')
        .setTitle(__('views.clients.form.contact_full_name'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setKey('phone')
        .setTitle(__('views.clients.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('company_email')
        .setTitle(__('views.clients.form.company_email'))
        .setRequiredDecoration(true)
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('reminder_email')
        .setEntryKey('reminderEmail')
        .setTitle(__('views.clients.form.reminder_email'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('send_reminders')
        .setTitle(__('views.clients.form.send_reminders'))
        .setSize(FieldSizes.half)
    ])
    .setInitialValues({
      company_name: this.itemDetails?.companyName
    })
    .setOnSuccess((response: any) => {
      this.$root.$emit('updateSearchField', {
        key: 'client_id',
        label: response.data.company_name
      })
    })

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
