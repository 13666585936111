























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Setting from '@/modules/settings/models/setting.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import SettingResponse from '@/modules/settings/interfaces/setting-response.interface'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ReminderSettings extends Vue {
  group: string = 'reminder'
  dismissCountDown: number = 0
  form: FormBase = new FormBase()
    .setEndpoint(`/settings/${this.group}`)
    .setModel(Setting)
    .setFields([
      new Field()
        .setKey('0.value')
        .setType(FieldTypes.checkbox)
        .setTitle(__(`views.settings.form.${this.group}.reminder-enabled`)),
      new SearchableField()
        .setKey('1.value')
        .setEntryKey('1.value')
        .setSize(FieldSizes.half)
        .setDisplayKey('title')
        .setValueKey('id')
        .loadItems({
          endpoint: '/reminder-templates',
          value: 'id',
          title: 'title'
        })
        .setTitle(__(`views.settings.form.${this.group}.reminder-template`)),
      new Field()
        .setKey('2.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.reminder-max-count`)),
      new Field()
        .setKey('3.value')
        .setSize(FieldSizes.half)
        .setType(FieldTypes.time)
        .setTitle(__(`views.settings.form.${this.group}.reminder-dispatch-time`)),
      new Field()
        .setKey('4.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.reminder-every-days`))
    ])

  async created(): Promise<void> {
    await http.get(`settings/${this.group}`)
      .then((response: IResponse<SettingResponse[]>) => {
        const setting = response.data.data
        setting.forEach((settingValue: any, index: number) => {
          this.form.setData({
            ...this.form.data,
            [index]: {
              value: index === 1 ? Number(settingValue.value) : settingValue.value,
              id: settingValue.id
            }
          })
        })
      })
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get postData(): any {
    const postData: any = { settings: {} }
    Object.keys(this.form.data).forEach((key: string) => {
      postData.settings[key] = { ...this.form.data[key], value: this.form.data[key].value ? this.form.data[key].value.toString() : null }
    })
    return postData
  }

  async submit(): Promise<void> {
    await http.post(`/settings/${this.group}`, this.postData)
      .then(() => (this.dismissCountDown = 3))
  }
}
