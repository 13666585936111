





















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import { Route } from 'vue-router'
import { GeneralRoutes } from '@/router/routes/general'
import { VatGetters } from '@/store/modules/vat/vat.getters'
import { VatActions } from '@/store/modules/vat/vat.actions'
import { mapActions, mapGetters } from 'vuex'
import Vat from './models/vat.model'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import DeleteComponent from '@/modules/settings/DeleteComponent.vue';

@Component({
  components: { PageHeader, LoadingSpinner },
  methods: {
    __,
    ...mapActions({
      vatsFetch: VatActions.fetch
    })
  },
  computed: {
    ...mapGetters({
      vats: VatGetters.getVats
    })
  }
})
export default class VATSIndex extends Vue {
  vats!: Vat[]
  vatsFetch!: () => any
  loading: boolean = true

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route, oldVal: Route): void {
    if (
      newVal.name === GeneralRoutes.settingsDocuments &&
      oldVal &&
      (
        oldVal.name === GeneralRoutes.documentsVATSCreate ||
        (oldVal.name === GeneralRoutes.documentsDeleteType && oldVal.params.type === 'vats')
      )
    ) {
      this.$store.dispatch(VatActions.fetch)
    }
  }

  async created(): Promise<void> {
    this.loading = true
    await this.vatsFetch()
    await this.vatsFetch()
      .then(() => {
        this.loading = false
      })
  }

  openDeleteModal(vat: Vat):void {
    this.$store.commit('setModalDialog', {
      component: DeleteComponent,
      id: vat.id,
      type: 'vats',
      props: {
        title: __('views.settings.delete.title', { data: vat.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.loading = true
          this.vatsFetch()
            .then(() => {
              this.loading = false
            })
        }
      }
    })
  }
}
