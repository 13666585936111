import Service from '@/modules/service/models/service.model'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import InvoiceTemplateResponse from '../interfaces/invoice-template-response'
import Client from '@/modules/client/models/client.model'
import User from '@/modules/user/models/user.model'
import { InvoiceTemplateTypeData } from '../config/invoice-template.config'
import moment from 'moment'

export default class InvoiceTemplate extends Model<IModelResponse> {
  static ALIAS: string = 'invoice-template'

  clientId!: number
  client!: Client
  managerId!: number
  manager!: User
  date!: string
  payInDays!: number
  generateEveryMonths!: number
  active!: string
  notes!: string
  locale!: string
  items!: any

  transform(data: InvoiceTemplateResponse): void {
    super.transform(data)

    this.clientId = data.client_id
    this.managerId = data.manager_id
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.payInDays = data.pay_in_days
    this.generateEveryMonths = data.generate_every_months
    this.active = data.active
    this.notes = data.notes
    this.locale = data.locale
    this.items = data.items
    this.client = this.setRelation(Client, data.client)
    this.manager = this.setRelation(User, data.manager)
  }
}

export class InvoiceTemplateTable extends Model<IModelResponse> {
  clientId!: number
  client!: Client
  managerId!: number
  manager!: User
  date!: string
  payInDays!: number
  generateEveryMonths!: number
  active!: string
  notes!: string
  locale!: string
  items!: Service[]
  total!: number
  total_with_vat!: number

  transform(data: InvoiceTemplateResponse): void {
    super.transform(data)

    this.clientId = data.client_id
    this.managerId = data.manager_id
    this.date = data.date
    this.payInDays = data.pay_in_days
    this.generateEveryMonths = data.generate_every_months
    this.active = InvoiceTemplateTypeData[String(data.active)]
    this.notes = data.notes
    this.locale = data.locale
    this.items = this.setRelation(Service, data.items)
    this.client = this.setRelation(Client, data.client)
    this.manager = this.setRelation(User, data.manager)
    this.total = data.total
    this.total_with_vat = data.total_with_vat
  }
}
