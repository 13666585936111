



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Service from '@/modules/service/models/service.model'
import { GeneralRoutes } from '@/router/routes/general'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { setPriceWithoutAmount } from '@/shared/configs/service.config'
import { VatGetters } from '@/store/modules/vat/vat.getters'
import { UnitGetters } from '@/store/modules/unit/unit.getters'
import getAll from '@/shared/configs/vuex/get-all.config'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class ServiceCreate extends Vue implements Submittable {
  form: FormBase | null = null

  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/services')
      .setModel(Service)
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.servicesIndex })
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('title')
          .setTitle(__('views.services.form.title'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('amount')
          .setTitle(__('views.services.form.amount'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true)
          .setType(FieldTypes.number)
          .setMinValue(0),
        new Field()
          .setKey('price_per_unit')
          .setTitle(__('views.services.form.price'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true)
          .setType(FieldTypes.number)
          .setMinValue(0)
          .setOnChange(setPriceWithoutAmount),
        new SearchableField()
          .setKey('unit')
          .setVuexGetter(UnitGetters.getUnits)
          .setNoUuid(true)
          .setTitle(__('views.invoices.form.units'))
          .setRequiredDecoration(true)
          .setValueKey('title')
          .setSize(FieldSizes.half),
        new SearchableField()
          .setKey('vat')
          .setVuexGetter(VatGetters.getVats)
          .setNoUuid(true)
          .setTitle(__('views.invoices.form.vats'))
          .setRequiredDecoration(true)
          .setValueKey('percentage')
          .setOnChange(setPriceWithoutAmount)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('price_with_vat')
          .setTitle(__('views.services.form.price_with_vat'))
          .setSize(FieldSizes.half)
          .setDisabled(true)
      ])
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
