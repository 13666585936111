










import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class OffersSaveAsTemplateSuccess extends Vue implements Submittable {
  @Prop() itemDetails!: any
  error: any = null

  submitDialog(): void {
    this.$root.$emit('formSuccess')
  }
}
