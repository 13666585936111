





import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import User from '@/modules/user/models/user.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class UserDisable extends Vue implements Submittable {
  user: User | any = null

  async created(): Promise<void> {
    await http
      .get(`users/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.user = item.data
      })
  }

  get title(): string {
    return this.user ? this.user.full_name : ''
  }

  submitDialog(): void {
    http
      .post(`users/${this.user.id}/disable`)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
  }
}
