

























import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { Submittable } from '@/shared/interfaces/submittable';
import DocumentTemplate from '@/modules/documents/models/document-template.model';
import DocumentTemplateService from '@/services/DocumentTemplateService';

@Component({
  methods: { __ }
})
export default class DocumentSaveAsTemplate extends Vue implements Submittable {
  @Prop() itemDetails!: any
  error: any = null
  templateTitle: string = ''
  data: DocumentTemplate = new DocumentTemplate()

  submitDialog(): void {
    this.error = null
    this.data.content = this.itemDetails.content
    DocumentTemplateService.submitDocumentTemplate(this.data)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
      .catch((error: any) => {
        this.error = error
      })
  }
}
