import Field from '@/shared/classes/components/form/field';

export default class DateField extends Field {
    preselectCurrentMonth: boolean = false

    setPreselectCurrentMonth(preselectCurrentMonth: boolean = true): this {
      this.preselectCurrentMonth = preselectCurrentMonth
      return this
    }
}
