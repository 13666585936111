







import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Setting from '@/modules/settings/models/setting.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import SettingResponse from '@/modules/settings/interfaces/setting-response.interface'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class DocumentSettings extends Vue {
  group: string = 'offer'
  form: FormBase = new FormBase()
    .setEndpoint(`/settings/${this.group}`)
    .setModel(Setting)
    .setSuccessAlert('Nustatymai išsaugoti')
    .setFields([
      new Field()
        .setKey('0.value')
        .setTitle(__(`views.settings.form.${this.group}.about-us`)),
      new Field()
        .setKey('1.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.offer-service`)),
      new Field()
        .setKey('2.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.offer-service`)),
      new Field()
        .setKey('3.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.offer-service`)),
      new Field()
        .setKey('4.value')
        .setSize(FieldSizes.half)
        .setTitle(__(`views.settings.form.${this.group}.offer-service`)),
      new Field()
        .setKey('5.value')
        .setTitle(__(`views.settings.form.${this.group}.latest-jobs`))
    ])
    .setChangeDataBeforeSubmit(this.postData)

  async created(): Promise<void> {
    this.form.setLoading(true)
    await http.get(`settings/${this.group}`)
      .then((response: IResponse<SettingResponse[]>) => {
        const setting = response.data.data
        setting.forEach((settingValue: any, index: number) => {
          this.form.setData({
            ...this.form.data,
            [index]: {
              value: settingValue.value,
              id: settingValue.id
            }
          })
        })
      })
      .finally(() => this.form.setLoading(false))
  }

  postData(): any {
    const postData: any = { settings: {} }
    Object.keys(this.form.data).forEach((key: string) => {
      postData.settings[key] = { ...this.form.data[key], value: this.form.data[key].value ? this.form.data[key].value : null }
    })
    return postData
  }
}
