import _ from 'lodash'

export function setPriceWithVat(form: any) {
  const target: any = _.find(form.fields, (item: any) => item.key === 'price_with_vat')
  const data = form.data
  const { vat, amount } = data
  const pricePerUnit = data.price_per_unit

  if (!vat && vat !== 0) target.value = 'Pasirinkite PVM'
  if (!amount) target.value = 'Įveskite kiekį'
  if (!pricePerUnit) target.value = 'Įveskite kainą'

  const totalPrice = amount * pricePerUnit

  let actualVat = 1

  if (vat !== 0) actualVat += vat / 100

  if ((vat || vat === 0) && amount && pricePerUnit) {
    target.value = (totalPrice * actualVat).toFixed(2)
  }
}

export function setPriceWithoutAmount(form: any) {
  const target: any = _.find(form.fields, (item: any) => item.key === 'price_with_vat')
  const data = form.data
  const { vat } = data
  const pricePerUnit = data.price_per_unit

  if (!vat && vat !== 0) target.value = 'Pasirinkite PVM'
  if (!pricePerUnit) target.value = 'Įveskite kainą'

  let actualVat = 1

  if (vat !== 0) actualVat += vat / 100

  if ((vat || vat === 0) && pricePerUnit) {
    target.value = (pricePerUnit * actualVat).toFixed(2)
  }
}
