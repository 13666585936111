


























import { Component, Prop, Vue } from 'vue-property-decorator'
import ProcurementListItemData from '@/modules/procurement/procurement-list-item-data.interface';
import __ from '@/helpers/__';
import ProcurementService from '@/services/ProcurementService';
import { GeneralRoutes } from '@/router/routes/general';
@Component({
  methods: { __ }
})
export default class ProcurementListItem extends Vue {
  @Prop() data?:ProcurementListItemData
  @Prop() canSave?: boolean
  @Prop() savedProcurements?: number[]

  GeneralRoutes = GeneralRoutes

  private formatDate(date: string): string {
    return date.split(' ')[0]
  }

  private toggleProcurementSave():void {
    if (typeof this.data !== 'undefined') {
      ProcurementService.saveProcurement({
        procurement_id: this.data.id,
        is_memorized: !this.isSaved
      }).then((response) => {
        this.$emit('procurementSaveToggle', { procurement_id: this.data?.id, is_memorized: response.data.is_memorized })
      })
    }
  }

  get isSaved(): boolean {
    if (typeof this.savedProcurements !== 'undefined' && typeof this.data !== 'undefined') {
      return this.savedProcurements.includes(this.data.id)
    }
    return false
  }

  onOwnerCLick() {
    this.$root.$emit('procurementOwnerClick', this.data?.contracting_authority)
  }

  onCodeCLick() {
    this.$root.$emit('procurementCodeClick', this.data?.bvpz_code)
  }
}
