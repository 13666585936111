import moment from 'moment';

export default function highlightExpiring(item: any): string {
  if (!item) {
    return ''
  }
  if (moment(item.validUntil).isBefore(moment().add(1, 'M'))) {
    return 'expiring'
  }
  return ''
}
