




























import { Component, Prop } from 'vue-property-decorator'

import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import Invoice from '@/modules/invoices/models/invoice.model';
import __ from '@/helpers/__';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}

@Component({
  components: { TableLoader },
  methods: { __ }
})
export default class PrepaidInvoiceServicesTable extends StickyTableHeader {
  @Prop() loading!: boolean
  @Prop() fields!: fieldType[]
  @Prop() invoice!: Invoice | any

  created() {
    setTimeout(() => {
      this.setStickyHeader()
    }, 100)
  }
}
