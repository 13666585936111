







import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import PrepaidInvoice from '@/modules/prepaid-invoices/models/prepaid-invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import { prepaidService } from '@/shared/configs/information-types.config'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import _ from 'lodash'
import moment from 'moment'
import User from '../user/models/user.model'
import { InvoiceStatusKeys, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import { UserGetters } from '@/store/modules/user/user.getters'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';

@Component({
  components: { PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class PrepaidInvoiceCreate extends Vue {
  form: FormBase | any = null
  loadingNumber: boolean = false
  validNumber: boolean = false
  user!: User

  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/invoices')
      .setModel(PrepaidInvoice)
      .setCancel(() => this.$router.push({ name: GeneralRoutes.prepaidInvoicesIndex }))
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.prepaidInvoicesIndex })
      })
      .setFields([
        new SearchableField()
          .setKey('client_id')
          .setLoadItemSort('-created_at')
          .setDisplayKey('company_name')
          .setTitle(__('views.invoices.form.client'))
          .setRequiredDecoration(true)
          .setLazy(true)
          .setSize(FieldSizes.full)
          .setNotFoundComponent(ClientNotFound)
          .loadItems({
            endpoint: '/clients',
            value: 'id',
            title: 'company_name'
          }),
        new Field()
          .setKey('number')
          .setTitle(__('views.invoices.form.number'))
          .setOnChange(_.debounce(this.checkIfValid, 400))
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.date)
          .setKey('date')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.date'))
          .setSize(FieldSizes.half),
        new ArrayField()
          .setKey('items')
          .setAppendData({ amount: 1 })
          .setCreateNewRow(true)
          .setFieldsTemplates({ service: prepaidService }),
        new Field()
          .setType(FieldTypes.date)
          .setKey('due_date')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.dueDate'))
          .setSize(FieldSizes.half),
        new SearchableField()
          .setKey('manager_id')
          .setDisplayKey('full_name')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.manager'))
          .setLazy(true)
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/users',
            value: 'id',
            title: 'full_name'
          }),
        new Field()
          .setKey('locale')
          .setTitle(__('views.invoices.form.language'))
          .setType(FieldTypes.checkbox)
          .setCheckedValue('en')
          .setUncheckedValue('lt'),
        new Field()
          .setKey('notes')
          .setTitle(__('views.invoices.form.notes'))
          .setSize(FieldSizes.full)
          .setType(FieldTypes.richEditor)
      ])
      .setInitialValues({
        date: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        client_id: this.$route.params.clientId || null
      })
      .setInjectValues({
        type: InvoiceTypes.prepaid,
        status: InvoiceStatusKeys.unpaid
      })

    http
      .get(`invoices/get-next-number/${InvoiceTypes.prepaid}`)
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.form.setData({
          ...this.form.data,
          number: response.data,
          manager_id: this.user.id
        })
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })
  }

  checkIfValid(form: FormBase): any {
    if (!this.loadingNumber && form.data.number) {
      this.loadingNumber = true
      http
        .get(`invoices/check-is-available/${form.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }
}
