










































































































































































import { Component, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import Offer from '@/modules/offer/models/offer.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import { Route } from 'vue-router'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import moment from 'moment'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import OffersDelete from '@/modules/offer/OffersDelete.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import { InvoiceStatusKeysData, OfferStatusKeys } from '@/shared/configs/offer.config';
import OfferService from '@/services/OfferService';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

@Component({
  components: { TableLoader, OffersDelete, DataTable, PageHeader },
  methods: { __ }
})
export default class OfferPage extends StickyTableHeader {
  offer: Offer | any = null
  downloaded: boolean = false
  copyText: string = __('views.offers.offer.copy')
  loading: boolean = true

  fields: Object[] = [
    { key: 'title', label: __('views.offers.table.title') },
    { key: 'price_per_unit', label: __('views.offers.table.price') },
    { key: 'unit', label: __('views.offers.table.unit') },
    { key: 'amount', label: __('views.offers.table.amount') },
    { key: 'discount_percentage', label: __('views.offers.table.discount') },
    { key: 'total', label: __('views.offers.table.total') },
    { key: 'vat', label: __('views.offers.table.vat') },
    { key: 'total_with_vat', label: __('views.offers.table.total-with-vat') }
  ]

  statusOptions: SelectOption[] = [
    new SelectOption().setKey(OfferStatusKeys.active).setTitle(InvoiceStatusKeysData[OfferStatusKeys.active]),
    new SelectOption().setKey(OfferStatusKeys.approved).setTitle(InvoiceStatusKeysData[OfferStatusKeys.approved]),
    new SelectOption().setKey(OfferStatusKeys.refused).setTitle(InvoiceStatusKeysData[OfferStatusKeys.refused]),
    new SelectOption().setKey(OfferStatusKeys.suspended).setTitle(InvoiceStatusKeysData[OfferStatusKeys.suspended])
  ]

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.offer && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  async copy(link: string): Promise<void> {
    await navigator.clipboard.writeText(link).then(() => {
      this.copyText = `${__('views.offers.offer.copied')}!`
      setTimeout(() => {
        this.copyText = __('views.offers.offer.copy')
      }, 3000)
    })
  }

  created(): void {
    http
      .get(`/offers/${this.$route.params.id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.offer = item.data
        this.$root.$emit('headingChange', {
          heading: __('views.offers.offer.title', { offer: this.offer.title })
        })
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
      })
  }

  updateStatus(status: string) {
    OfferService.updateStatus(Number(this.$route.params.id), { status })
  }

  createInvoice(): void {
    const { information, notes } = this.offer
    const items = Object.keys(information)
      .filter((key: string) => information[key].type === 'service')
      .reduce((object: any, key: string) => {
        object[key] = information[key]
        return object
      }, {})

    const data: any = {
      client_id: this.offer.client_id,
      date: moment().format('YYYY-MM-DD'),
      due_date: this.offer.due_date,
      items,
      manager_id: this.offer.manager_id,
      notes
    }

    this.$router.push({
      name: GeneralRoutes.invoicesCreate,
      params: { data }
    })
  }

  createDocument(): void {
    this.$router.push({ name: GeneralRoutes.documentsCreate, params: { offerId: this.$route.params.id } })
  }

  get sortedItems(): any {
    return this.offer && this.offer.information
      ? this.offer.information.sort((a: any, b: any) => a.position - b.position)
      : null
  }

  duplicateOffer(): void {
    this.$router.push({
      name: GeneralRoutes.offersCreate,
      params: { id: this.offer.id }
    })
  }

  editOffer(): void {
    this.$router.push({ name: GeneralRoutes.offersEdit, params: { id: this.$route.params.id } })
  }

  downloadPDF(): void {
    this.downloaded = true
    http
      .get(`offer-pdf/${this.$route.params.id}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
      .finally(() => {
        this.downloaded = false
      })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: OffersDelete,
      id: this.offer.id,
      props: {
        title: __('views.offers.delete.title', { offer: this.offer.title }),
        size: 'md',
        submitLabel: __('form.delete')
      }
    })
  }
}
