import TextHeader from '@/shared/components/data-table/header-types/TextHeader.vue'

enum DataTableHeaderTypes {
  text = 'text',
}

export default DataTableHeaderTypes

export const dataTableHeaderMap = {
  [DataTableHeaderTypes.text]: TextHeader
}
