





import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';
import http from '@/shared/helpers/http';

@Component({
  methods: { __ }
})
export default class ProjectsDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  @Prop() itemDetails!: any

  submitDialog(): void {
    http
      .delete(`projects/${this.itemId}`)
      .then(() => {
        this.$root.$emit('formSuccess')
        this.$router.push({ name: GeneralRoutes.projectsIndex })
      })
  }
}
