





import { Component } from 'vue-property-decorator'
import AbstractHeader from '@/shared/components/data-table/header-types/AbstractHeader'

@Component
export default class TextHeader extends AbstractHeader {
  get parsedValue(): any {
    return this.value || this.header.defaultValue
  }
}
