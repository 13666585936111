import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import moment from 'moment'
import IProjectResponse from '@/modules/project/interfaces/project-response.interface';

export default class Project extends Model<IModelResponse> {
  static ALIAS: string = 'project'
  id!: number
  title!: string
  client!: Object
  clientId!: number
  url!: string
  validUntil!: string
  status!: string
  cmsType!: string
  monthPrice!: number
  type!: string
  comment!: string

  transform(data: IProjectResponse): void {
    super.transform(data)
    this.id = data.id
    this.title = data.title
    this.client = data.client
    this.clientId = data.client_id
    this.url = data.url
    this.validUntil = moment(data.valid_until).format('YYYY-MM-DD')
    this.status = data.status
    this.cmsType = data.cms_type
    this.monthPrice = data.month_price || 0
    this.type = data.type
    this.comment = data.comment
  }
}
