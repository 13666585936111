










import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import DocumentClass from './models/document.model'
import DocumentService from '@/services/DocumentService'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class DocumentFileDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  GeneralRoutes = GeneralRoutes
  document: DocumentClass | any = null
  error: string = ''
  dismissCountDown: number = 0

  submitDialog(): void {
    DocumentService.removeDocumentFile(this.itemId).then(() => {
      this.$root.$emit('formSuccess')
    })
  }
}
