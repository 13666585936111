import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IClientDocumentResponse from '../interfaces/client-document-response.interface'

export default class ClientDocument extends Model<IModelResponse> {
  static ALIAS: string = 'client-document'

  number!: string
  documentTemplateId!: number
  clientId!: number
  date!: string
  managerId!: number
  content!: string
  title!: string

  transform(data: IClientDocumentResponse): void {
    super.transform(data)

    this.number = data.number
    this.documentTemplateId = data.document_template_id
    this.clientId = data.client_id
    this.date = data.date
    this.managerId = data.manager_id
    this.content = data.content
    this.title = data.title
  }
}
