import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IClientResponse from '@/modules/client/interfaces/client-response.interface'

export default class Client extends Model<IModelResponse> {
  static ALIAS: string = 'client'

  companyName!: string
  companyAddress!: string
  companyCode!: string
  companyVatCode!: string
  companyDirector!: string
  contactFullName!: string
  companyEmail!: string
  phone!: string
  sendReminders!: string
  reminderEmail!: string
  stats!: any

  transform(data: IClientResponse): void {
    super.transform(data)

    this.companyName = data.company_name
    this.companyAddress = data.company_address
    this.companyCode = data.company_code
    this.companyVatCode = data.company_vat_code
    this.companyDirector = data.company_director
    this.contactFullName = data.contact_full_name
    this.companyEmail = data.company_email
    this.phone = data.phone
    this.sendReminders = data.send_reminders.toString()
    this.reminderEmail = data.reminder_email
    this.stats = data.stats
  }
}
