



import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Reminder from '@/modules/reminder-template/models/reminder.model';
import ClientReminderModal from '@/modules/client/ClientReminderModal.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class ClientReminders extends Vue {
  table: DataTableBase | null = null

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientReminders)
      .setModel(Reminder)
      .setEndpoint(`/invoices/reminder/client/${this.$route.params.id}`)
      .setRowClickCallback((item:Reminder) => {
        this.viewDetails(item)
      })
      .setHeaders([
        new DataTableHeader().setKey('sendDate').setLabel(__('views.offers.index.table.columns.date')),
        new DataTableHeader().setKey('sendDate').setLabel(__('views.offers.index.table.columns.date')),
        new DataTableHeader()
          .setKey('invoice.number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.invoice, linkParam: 'invoice.id' }),
        new DataTableHeader().setKey('invoice.totalWithVat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('invoice.payed').setLabel(__('views.invoices.index.table.columns.payed')),
        new DataTableHeader().setKey('invoice.leftPay').setLabel(__('views.invoices.index.table.columns.leftPay')),
        new DataTableHeader().setKey('invoice.dueDate').setLabel(__('views.invoices.index.table.columns.dueDate'))
      ])
  }

  viewDetails(item: Reminder): void {
    this.$store.commit('setModalDialog', {
      component: ClientReminderModal,
      id: item.id,
      props: {
        title: item.invoice.number,
        size: 'md'
      },
      details: {
        content: item.content
      },
      hideFooter: true
    })
  }
}
