





import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Template from '@/modules/offer-template/models/template.model'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class TemplatesDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  GeneralRoutes = GeneralRoutes
  offerTemplate: Template | any = null

  async created(): Promise<void> {
    await http
      .get(`offer-templates/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.offerTemplate = item.data
      })
  }

  get title(): string {
    return this.offerTemplate ? this.offerTemplate.title : ''
  }

  submitDialog(): void {
    http
      .delete(`offer-templates/${this.itemId}`)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
  }
}
