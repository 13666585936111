import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import moment from 'moment'
import IProcurementCompanyResponse, {
  IProcurementCompanyDetails
} from '@/modules/offer/interfaces/procurement-company-response.interface';

export default class ProcurementCompany extends Model<IModelResponse> {
  static ALIAS: string = 'procurement-company'

  title!: string
  url!: string
  procurementCount!: number
  latestProcurementDate!: string
  details!: IProcurementCompanyDetails | null

  transform(data: IProcurementCompanyResponse): void {
    super.transform(data)

    this.title = data.title
    this.url = data.url
    this.procurementCount = data.procurementCount
    this.latestProcurementDate = moment(data.latestProcurementDate).format('YYYY-MM-DD')
    this.details = data.details
  }
}
