


















import { Component, Prop, Vue } from 'vue-property-decorator'
import ProcurementSelectItem from '@/modules/procurement/ProcurementSelectItem.vue';
import _ from 'lodash';
import ProcurementService from '@/services/ProcurementService';
import ProcurementCategory from '@/modules/procurement/interfaces/procurement-category.interface';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import __ from '@/helpers/__'
import SavedProcurementCategory from '@/modules/procurement/interfaces/saved-procurement-category.interface';
import ProcurementSelectLoader from '@/modules/procurement/ProcurementSelectLoader.vue';

@Component({
  components: { ProcurementSelectLoader, LoadingSpinner, ProcurementSelectItem },
  methods: { __ }
})
export default class ProcurementSelect extends Vue {
  @Prop({ default: false }) loadSaved?: boolean
  @Prop({ default: __('views.procurement.index.find') }) buttonLabel?: string
  @Prop({ default: false }) deleteSavedCategories?: boolean

  $refs!: any

  private itemStatus: { [key: string]: number } = {}
  private data: ProcurementCategory | null = null
  private procurements: ProcurementCategory[] = []
  private savedCategories: SavedProcurementCategory[] = []

  created(): void {
    ProcurementService.getCategories()
      .then((response: any) => {
        this.data = response
        this.procurements = response
      })
      .then(() => {
        if (this.loadSaved) {
          ProcurementService.getSavedCategories()
            .then((response) => {
              this.savedCategories = response
              response.forEach(code => {
                if (code.procurement_code.length < 8) {
                  code.procurement_code = code.procurement_code + '0'.repeat(8 - code.procurement_code.length)
                }
                this.itemStatus[code.procurement_code] = 1
                this.$root.$emit('savedCategoryLoad', code.procurement_code)
              })
            })
        }
      })

    this.$root.$on('updateProcurementSelection', (updateData: SelectionUpdateData) => {
      const selectedItemStatus = _.has(this.itemStatus, updateData.ref)
      if ((!selectedItemStatus && updateData.value === 1) || (this.itemStatus[updateData.ref] === -1 && updateData.value === 1)) {
        this.$set(this.itemStatus, updateData.ref, updateData.value)
        return
      }
      if ((selectedItemStatus && updateData.value === 0) || (this.itemStatus[updateData.ref] === -1 && updateData.value === 1)) {
        this.$delete(this.itemStatus, updateData.ref)
        return
      }
      if (updateData.value === -1) {
        this.$set(this.itemStatus, updateData.ref, updateData.value)
      }
    })
  }

  private submitCategories() {
    const categories: string[] = [];
    Object.keys(this.itemStatus).forEach((key) => {
      if (this.itemStatus[key] === 1) {
        let trimmedKey = key.replace(new RegExp('0' + '+$'), '')
        if (trimmedKey.length === 1) {
          trimmedKey += '0'
        }
        categories.push(trimmedKey)
      }
    });
    categories.sort(function(a, b) {
      return a.localeCompare(b)
    });
    if (categories.length === 0) {
      return
    }
    const payload: string[] = [categories[0]]
    if (categories.length > 1) {
      let compareTo = 0
      categories.forEach((category, index) => {
        if (index > 0 && !category.startsWith(categories[compareTo])) {
          payload.push(category)
          compareTo = index
        }
      })
    }
    if (payload) {
      if (this.deleteSavedCategories && this.procurements) {
        const deletePayload = this.savedCategories.map(
          (procurement: SavedProcurementCategory) => {
            let processedCode = procurement.procurement_code.replace(new RegExp('0' + '+$'), '')
            if (processedCode.length === 1) {
              processedCode += '0'
            }
            return { code: processedCode }
          }
        )
        ProcurementService.deleteCategories({ procurements: deletePayload }).finally(() => {
          this.$emit('searchCategories', payload)
        })
      } else {
        this.$emit('searchCategories', payload)
      }
    }
  }

  get canSubmit(): boolean {
    return _.isEmpty(this.itemStatus)
  }
}

interface SelectionUpdateData {
  ref: string,
  value: number
}

