













































































import { Component, Vue } from 'vue-property-decorator'
import ProcurementService from '@/services/ProcurementService';
import ProcurementData from '@/modules/procurement/interfaces/procurement-data.interface';
import __ from '@/helpers/__';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import ProcurementDeleteModal from '@/modules/procurement/ProcurementDeleteModal.vue';

@Component({
  components: { LoadingSpinner },
  methods: { __ }
})
export default class ProcurementInner extends Vue {
  data: ProcurementData | null = null
  loading: boolean = true
  comment: string = ''
  editComment: boolean = false

  created(): void {
    this.fetchData()
  }

  private fetchData(): void {
    if (typeof this.$route.params.id !== 'undefined') {
      this.loading = true
      ProcurementService.getProcurementContent(this.$route.params.id).then((response) => {
        this.data = response
        this.$root.$emit('headingChange', {
          heading: this.data.title
        })
        this.comment = this.data.details?.comment || ''
      }).finally(() => {
        this.loading = false
      })
    }
  }

  private formatDate(date: string): string {
    return date.split(' ')[0]
  }

  private saveProcurement(comment: string | undefined = undefined): void {
    if (this.data !== null) {
      ProcurementService.saveProcurement({
        procurement_id: this.data.id,
        is_memorized: true,
        comment
      }).then(() => {
        this.fetchData()
      })
    }
  }

  private saveComment(): void {
    this.saveProcurement(this.comment)
    this.editComment = false
  }

  private cancelComment(): void {
    this.editComment = false
    this.comment = this.data?.details.comment || ''
  }

  private confirmDelete(): void {
    this.$store.commit('setModalDialog', {
      component: ProcurementDeleteModal,
      id: this.data?.id,
      props: {
        title: __('views.procurement.inner.delete'),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.fetchData()
        }
      }
    })
  }
}
