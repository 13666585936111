

























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Invoice from '@/modules/invoices/models/invoice.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SelectOption from '@/shared/classes/components/form/select-option'
import { InvoiceStatusKeys, InvoiceStatusKeysData, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import http from '@/shared/helpers/http'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import highlightOverdue from '@/shared/helpers/data-table/highlight-overdue';
import CheckboxField from '@/shared/classes/components/form/fields/checkbox-field';
import { tableCount } from '@/helpers/tableCount';
import DateField from '@/shared/classes/components/form/fields/date-field';
import ProjectService from '@/services/InvoicesService';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class InvoiceIndex extends Vue {
  table: DataTableBase | any = null
  downloaded: boolean = true
  excelDownloaded: boolean = true
  sendingInvoices: boolean = false
  showAlert: boolean = false
  alertVariant: string = 'success'
  alertMessage: string = ''

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.invoicesIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.invoicesIndex)
      .setModel(Invoice)
      .setEndpoint('/invoices')
      .setDefaultSortBy('number')
      .setFilterAlwaysOpen(true)
      .setRowClassCallback(highlightOverdue)
      .setPerPageUnderFilter(true)
      .setRowClickCallback((item: Invoice) => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: item.id.toString() } })
      })
      .setSearchAlwaysVisible(true)
      .setFilter([
        new DataTableFilter().setOperator(FilterOperators.in).setField(
          new SearchableField()
            .setNoUuid(true)
            .setInternalSearch(true)
            .setMultiple(true)
            .setKey('client_id')
            .setLoadItemSort('-created_at')
            .setDisplayKey('company_name')
            .setTitle('Klientas')
            .setValueKey('id')
            .setSize(FieldSizes.threeTwelfth)
            .loadItems({
              endpoint: '/clients',
              value: 'id',
              title: 'company_name'
            })
        ),
        new DataTableFilter().setOperator(FilterOperators.equals).setField(
          new SearchableField()
            .setNoUuid(true)
            .setInternalSearch(true)
            .setKey('manager.full_name')
            .setTitle('Vadybininkas')
            .setDisplayKey('full_name')
            .setValueKey('full_name')
            .setActiveItemsOnly(true)
            .setSize(FieldSizes.threeTwelfth)
            .loadItems({
              endpoint: '/users',
              value: 'id',
              title: 'full_name'
            })
        ),
        new DataTableFilter()
          .setFilterReturnParam(this.dateFilter)
          .setFilterSetValue(this.dateSetFilter)
          .setField(
            new DateField()
              .setType(FieldTypes.dateFilter)
              .setSize(FieldSizes.half)
              .setTitle('Data')
              .setKey('date')
              .setPreselectCurrentMonth(true)
              .setSelectOptions([
                new SelectOption().setKey('current-month').setTitle('Einamasis mėnuo'),
                new SelectOption().setKey('last-month').setTitle('Praėjęs mėnuo'),
                new SelectOption().setKey('select').setTitle('Pasirinktina data')
              ])
          ),
        new DataTableFilter().setOperator(FilterOperators.in).setField(
          new CheckboxField()
            .setKey('status')
            .setMultiple(true)
            .setSize(FieldSizes.half)
            .setType(FieldTypes.checkboxBlock)
            .setSelectOptions([
              new SelectOption()
                .setKey(InvoiceStatusKeys.unpaid)
                .setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.unpaid]),
              new SelectOption()
                .setKey(InvoiceStatusKeys.partiallyPaid)
                .setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.partiallyPaid]),
              new SelectOption().setKey(InvoiceStatusKeys.paid).setTitle(InvoiceStatusKeysData[InvoiceStatusKeys.paid])
            ])
        ),
        { operator: 'equals', type: 'type', value: InvoiceTypes.standard }
      ])
      .setHeaders([
        new DataTableHeader()
          .setKey('number')
          .setLabel(__('views.invoices.index.table.columns.number'))
          .setLink({ link: GeneralRoutes.invoice, linkParam: 'id' })
          .setPopover('notes'),
        new DataTableHeader().setKey('client.company_name').setLabel(__('views.invoices.index.table.columns.client')),
        new DataTableHeader().setKey('manager.full_name').setLabel(__('views.invoices.index.table.columns.manager')),
        new DataTableHeader().setKey('total').setLabel(__('views.invoices.index.table.columns.total')),
        new DataTableHeader().setKey('totalWithVat').setLabel(__('views.invoices.index.table.columns.totalWithVat')),
        new DataTableHeader().setKey('payed').setLabel(__('views.invoices.index.table.columns.payed')),
        new DataTableHeader().setKey('leftPay').setLabel(__('views.invoices.index.table.columns.leftPay')),
        new DataTableHeader().setKey('date').setLabel(__('views.invoices.index.table.columns.date')),
        new DataTableHeader().setKey('dueDate').setLabel(__('views.invoices.index.table.columns.dueDate')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.invoices.index.table.columns.status'))
          .setFunctionalClasses((data: Invoice) => `data-table__status data-table__status--${data.status}`)
          .setCustomText((data: Invoice) => {
            if (data.status === 'paid') return __('common-names.paid')

            if (data.status === 'partially-paid') return __('common-names.partially-paid')

            if (data.status === 'not-approved') return __('common-names.unapproved')

            return __('common-names.unpaid')
          })
      ])
      .setSumCheckboxes(true)
  }

  sendReminders():void {
    this.sendingInvoices = true
    ProjectService.sendInvoices().then(() => {
      this.alertVariant = 'success'
      this.alertMessage = 'views.invoices.invoice.success'
      this.showAlert = true
    }).catch((e: any) => {
      this.alertVariant = 'danger'
      this.alertMessage = e.response.data.message
      this.showAlert = true
    }).finally(() => {
      this.sendingInvoices = false
    })
  }

  addNew(): void {
    this.$router.push({ name: GeneralRoutes.invoicesCreate })
  }

  dateFilter(data: any) {
    const from = data.from ? { [`filter.${FilterOperators.gte}.date`]: data.from } : ''
    const to = data.to ? { [`filter.${FilterOperators.lte}.date`]: data.to } : ''
    return { ...from, ...to }
  }

  get totals(): any {
    const data: any = {
      total: 0,
      totalWithVat: 0,
      totalVat: 0
    }

    this.table.data.forEach((row: any) => {
      data.total += row.total
      data.totalWithVat += row.totalWithVat
      data.totalVat += row.totalVat
    })

    return data
  }

  get routeFilter(): string {
    let query: string = `?filter[equals.type]=${InvoiceTypes.standard}`
    Object.keys(this.$route.query).forEach((key: string) => {
      if (key.startsWith('filter.')) {
        query += `&filter[${key.replace(/filter./g, '')}]=${this.$route.query[key]}`
      }

      if (key === 'q') query += `&${key}=${this.$route.query[key]}`
    })
    return query
  }

  downloadPDF(): void {
    const ref: any = this.$refs.table
    if (!ref) return
    this.downloaded = false
    http
      .get(`invoice-pdf/by-request${ref.filtersCombined}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBufferWithoutName(response, 'Invoices.pdf')
      })
      .finally(() => {
        this.downloaded = true
      })
  }

  downloadExcel(): void {
    const ref: any = this.$refs.table
    if (!ref) return
    this.excelDownloaded = false
    http
      .get(`invoices/excel/xlsx${ref.filtersCombined}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBufferWithoutName(response, 'Invoices.xlsx')
      })
      .finally(() => {
        this.excelDownloaded = true
      })
  }

  dateSetFilter(field: any, data: any, operator?: string) {
    if (operator === FilterOperators.gte) {
      field.value = {
        ...field.value,
        from: data
      }
    }

    if (operator === FilterOperators.lte) {
      field.value = {
        ...field.value,
        to: data
      }
    }
  }

  private closeModal(): void {
    this.$root.$emit('closeModal')
  }
}
