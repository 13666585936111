import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IWorkerResponse from '../interfaces/worker-response.interface'

export default class WorkerClass extends Model<IModelResponse> {
  static ALIAS: string = 'worker'

  email!: string
  fullName!: string
  personalCode!: string
  phone!: string
  address!: string
  position!: string

  transform(data: IWorkerResponse): void {
    super.transform(data)

    this.email = data.email
    this.fullName = data.full_name
    this.personalCode = data.personal_code
    this.phone = data.phone
    this.address = data.address
    this.position = data.position
  }
}
