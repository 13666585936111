import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import IDataTableClasses from '@/shared/interfaces/data-table/data-table-classes.interface'
import DataTableLogo from './data-table-logo'

export default class DataTableBase {
  id!: string
  model!: Model<IModelResponse>
  data: any[] = []
  endpoint: string = 'test'
  headers: DataTableHeader[] = []
  actions: DataTableAction[] = []
  sumCheckboxes: boolean = false
  loading: boolean = false
  isSortable: boolean = true
  filters: Object[] = []
  linkRow: Object = {}
  additionalFilter: string = ''
  queryArray: any = {}
  logo: DataTableLogo | null = null
  classes: IDataTableClasses = {
    tableCard: '',
    table: ''
  }

  perPage: number = 10
  params: any = null
  rowHover: boolean = false
  filterAlwaysOpen: boolean = false
  searchAlwaysVisible: boolean = false
  defaultSortBy: string = 'created_at'
  heading: string = ''
  perPageUnderFilter: boolean = false

  onChange!: (items: any[]) => void
  rowClassCallback!: Function
  rowClickCallback: Function = () => {}

  setQueryArray(queryArray: any): this {
    this.queryArray = queryArray
    return this
  }

  setFilterAlwaysOpen(filterAlwaysOpen: boolean): this {
    this.filterAlwaysOpen = filterAlwaysOpen
    return this
  }

  setSearchAlwaysVisible(searchAlwaysVisible: boolean): this {
    this.searchAlwaysVisible = searchAlwaysVisible
    return this
  }

  setHeading(heading: string): this {
    this.heading = heading
    return this
  }

  setPerPageUnderFilter(perPageUnderFilter: boolean): this {
    this.perPageUnderFilter = perPageUnderFilter
    return this
  }

  setLogo(logo: DataTableLogo): this {
    this.logo = logo
    return this
  }

  setModel(model: any): DataTableBase {
    this.model = model
    return this
  }

  setLoading(loading: boolean): DataTableBase {
    this.loading = loading
    return this
  }

  setEndpoint(endpoint: string): DataTableBase {
    this.endpoint = endpoint
    return this
  }

  setHeaders(headers: DataTableHeader[]): DataTableBase {
    this.headers = headers
    return this
  }

  setActions(actions: DataTableAction[]): DataTableBase {
    this.actions = actions
    return this
  }

  setData(data: any[]): DataTableBase {
    this.data = data
    this.onChange && this.onChange(data)
    return this
  }

  setIsSortable(isSortable: boolean): DataTableBase {
    this.isSortable = isSortable
    return this
  }

  setPerPage(perPage: number): this {
    this.perPage = perPage
    return this
  }

  setParams(params: any): this {
    this.params = params
    return this
  }

  setOnChange(onChange: (items: any[]) => void): this {
    this.onChange = onChange
    return this
  }

  setFilter(filter: any): this {
    this.filters = filter
    return this
  }

  setLinkRow(linkRow: Object): this {
    this.linkRow = linkRow
    return this
  }

  setAdditionalFilter(additionalFilter: string): this {
    this.additionalFilter = additionalFilter
    return this
  }

  setRowClassCallback(rowClassCallback: Function): this {
    this.rowClassCallback = rowClassCallback
    return this
  }

  setRowClickCallback(rowClickCallback: Function): this {
    this.rowClickCallback = rowClickCallback
    this.rowHover = true
    return this
  }

  setSumCheckboxes(sumCheckboxes: boolean): this {
    this.sumCheckboxes = sumCheckboxes
    return this
  }

  setId(id: string): this {
    this.id = id
    return this
  }

  setDefaultSortBy(defaultSortBy: string): this {
    this.defaultSortBy = defaultSortBy
    return this
  }
}
