import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IMaskResponse from '../interfaces/mask-response.interface'

export default class DocumentMask extends Model<IModelResponse> {
  static ALIAS: string = 'document-mask'

  mask?: string
  count?: number

  transform(data: IMaskResponse): void {
    super.transform(data)

    this.mask = data.mask
    this.count = data.count
  }
}
