import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IPaymentResponse from '@/modules/payment/interfaces/payment-response.interface'
import moment from 'moment'

export default class Payment extends Model<IModelResponse> {
  static ALIAS: string = 'payment'

  invoice!: any
  invoice_id?: number
  type!: string
  date!: string
  fullPayment!: string
  amount!: number
  notes!: string
  prepaidInvoiceId!: number

  transform(data: IPaymentResponse): void {
    super.transform(data)

    this.id = data.id
    this.invoice = data.invoice
    this.invoice_id = data.invoice_id
    this.type = data.type
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.fullPayment = data.full_payment.toString()
    this.amount = data.amount
    this.notes = data.notes
    this.prepaidInvoiceId = data.prepaid_invoice_id
  }
}
