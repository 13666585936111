import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IVatResponse from '@/modules/settings/interfaces/vat-response.interface'

export default class Vat extends Model<IModelResponse> {
  static ALIAS: string = 'vat'

  title!: string
  percentage!: number

  transform(data: IVatResponse): void {
    super.transform(data)

    this.title = data.title
    this.percentage = data.percentage
    this.id = data.id
  }
}
