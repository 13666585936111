import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import moment from 'moment'
import IReminderResponse from '@/modules/reminder-template/interfaces/reminder-response';

export default class Reminder extends Model<IModelResponse> {
    static ALIAS: string = 'reminder'

    id!: number
    invoiceId!: number
    sendDate!: string
    status!: string
    content!: string
    invoice!: IReminderInvoice

    transform(data: IReminderResponse): void {
      super.transform(data)

      this.id = data.id
      this.invoiceId = data.invoice_id
      this.sendDate = moment(data.send_date).format('YYYY-MM-DD')
      this.status = data.status
      this.content = data.content
      this.invoice = {
        number: data.invoice.number,
        id: data.invoice.id,
        totalWithVat: data.invoice.total_with_vat,
        payed: data.invoice.payed,
        leftPay: data.invoice.left_pay,
        dueDate: moment(data.invoice.due_date).format('YYYY-MM-DD')
      }
    }
}

interface IReminderInvoice {
    number: string;
    id: number;
    totalWithVat: string
    payed: string
    leftPay: string
    dueDate: string
}
