

































































































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import _ from 'lodash'
import http from '@/shared/helpers/http'
import DocumentTemplate from './models/document-template.model'
import CustomKey from './models/custom-keys.model'
import { DefaultTagValues } from '@/shared/configs/documents.config'
import { GeneralRoutes } from '@/router/routes/general'
import Mask from '@/shared/classes/mask'
import { FieldSizes } from '@/shared/classes/components/form/field'
import DocumentTemplateService from '@/services/DocumentTemplateService';

interface IMasksResponse {
  meta: any
  masks: any[]
}

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class DocumentTemplateCreate extends Vue {
  data: DocumentTemplate = new DocumentTemplate()
  DefaultTagValues = DefaultTagValues
  maskData: Mask[] = []
  maskMeta: any = null
  FieldSizes = FieldSizes
  fileLoading: boolean = false

  async created(): Promise<void> {
    http.get(`document-templates/${this.$route.params.id}`).then(response => {
      this.data = new DocumentTemplate(response.data.data)
      this.$root.$emit('headingChange', {
        heading: __('views.documents.templates.edit.title', { template: this.title })
      })
    })
  }

  async downloadPDF(): Promise<void> {
    this.fileLoading = true
    await DocumentTemplateService
      .getDocumentTemplatePDF(this.$route.params.id)
      .catch(() => { this.openFileErrorModal() })
      .finally(() => { this.fileLoading = false })
  }

  async downloadDOC(): Promise<void> {
    this.fileLoading = true
    await DocumentTemplateService
      .getDocumentTemplateDOC(this.$route.params.id)
      .catch(() => { this.openFileErrorModal() })
      .finally(() => { this.fileLoading = false })
  }

  addNewKey(): void {
    if (this.data.keys) this.data.keys.push(new CustomKey())
  }

  get keys(): string[] {
    const defaultKeys = this.data.defaultKeys
    let keysResult: string[] = []
    if (defaultKeys) {
      Object.keys(defaultKeys).forEach((key: string) => {
        // @ts-ignore
        if (defaultKeys[key] === '1') keysResult = [...keysResult, ...DefaultTagValues[key].keys]
      })
    }

    const customKeys: string[] = []
    if (this.data.keys) {
      _.each(this.data.keys, (key: CustomKey) => {
        if (key.key) customKeys.push(`{${key.key}}`)
      })
    }

    customKeys.push('{company_director_sign}')

    return keysResult.concat(customKeys)
  }

  async submit(): Promise<void> {
    delete this.data.expired_at
    // @ts-ignore
    delete this.data.expiredAt
    await http
      .put(`document-templates/${this.data.id}`, this.data.toPostData())
      .then(() => this.$router.push({ name: GeneralRoutes.documentsTemplatesIndex }))
  }

  get maskOptions() {
    return this.maskData.map((mask: Mask) => ({ value: mask.id, label: mask.mask }))
  }

  get title() {
    return this.data.title || ''
  }

  openFileErrorModal(): void {
    this.$store.commit('setModalDialog', {
      id: this.$route.params.id,
      hideFooter: true,
      props: {
        titleClass: 'c-red text-center fs-18-important w-100',
        title: __('views.documents.error'),
        size: 'md',
        hideFooter: true,
        onSuccess: () => {
          console.log()
        }
      }
    })
  }
}
