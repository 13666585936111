export default class DataTableAction {
  icon!: string
  iconClass!: string
  title!: string
  action!: (item: any) => void
  visible!: (item: any) => boolean

  setIcon(icon: string): DataTableAction {
    this.icon = icon
    return this
  }

  setTitle(title: string): DataTableAction {
    this.title = title
    return this
  }

  setIconClass(iconClass: string): DataTableAction {
    this.iconClass = iconClass
    return this
  }

  setAction(action: (item: any) => void): DataTableAction {
    this.action = action
    return this
  }

  setVisible(visible: (item: any) => boolean): DataTableAction {
    this.visible = visible
    return this
  }
}
