import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IReminderTemplateResponse from '@/modules/reminder-template/interfaces/reminder-template-response.interface'

export default class Template extends Model<IModelResponse> {
  static ALIAS: string = 'template'

  title!: string
  body!: string

  transform(data: IReminderTemplateResponse): void {
    super.transform(data)

    this.title = data.title
    this.body = data.body
    this.createdAt = data.created_at
  }
}
