










import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import DocumentClass from './models/document.model'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class DocumentDelete extends Vue implements Submittable {
  @Prop() itemId!: number
  GeneralRoutes = GeneralRoutes
  document: DocumentClass | any = null
  error: string = ''
  dismissCountDown: number = 0

  async created(): Promise<void> {
    await http
      .get(`documents/${this.itemId}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.document = item.data
      })
  }

  get title(): void {
    return this.document ? this.document.number : ''
  }

  submitDialog(): void {
    http
      .delete(`documents/${this.itemId}`)
      .then(() => {
        this.$root.$emit('formSuccess')
      })
      .catch((error: any) => {
        this.error = error.response.data.errors.delete
        this.dismissCountDown = 10
      })
  }
}
