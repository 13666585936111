




















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import { Route } from 'vue-router'
import { GeneralRoutes } from '@/router/routes/general'
import { UnitActions } from '@/store/modules/unit/unit.actions'
import { UnitGetters } from '@/store/modules/unit/unit.getters'
import { mapActions, mapGetters } from 'vuex'
import Unit from './models/unit.model'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import DeleteComponent from '@/modules/settings/DeleteComponent.vue';

@Component({
  components: { PageHeader, LoadingSpinner },
  methods: {
    __,
    ...mapActions({
      unitsFetch: UnitActions.fetch
    })
  },
  computed: {
    ...mapGetters({
      units: UnitGetters.getUnits
    })
  }
})
export default class UnitsIndex extends Vue {
  unitsFetch!: () => any
  units!: Unit[]
  loading: boolean = true

  @Watch('$route', { immediate: true, deep: true }) private onUrlChange(newVal: Route, oldVal: Route): void {
    if (
      newVal.name === GeneralRoutes.settingsDocuments &&
      oldVal &&
      (
        oldVal.name === GeneralRoutes.documentsUnitsCreate ||
        (oldVal.name === GeneralRoutes.documentsDeleteType && oldVal.params.type === 'units')
      )
    ) {
      this.$store.dispatch(UnitActions.fetch)
    }
  }

  async created(): Promise<void> {
    this.loading = true
    await this.unitsFetch()
      .then(() => {
        this.loading = false
      })
  }

  openDeleteModal(unit: Unit):void {
    this.$store.commit('setModalDialog', {
      component: DeleteComponent,
      id: unit.id,
      type: 'units',
      props: {
        title: __('views.settings.delete.title', { data: unit.title }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.loading = true
          this.unitsFetch()
            .then(() => {
              this.loading = false
            })
        }
      }
    })
  }
}
