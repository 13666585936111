







import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Invoice from '@/modules/invoices/models/invoice.model'
import { HttpMethod } from '@/helpers/requests'
import { GeneralRoutes } from '@/router/routes/general'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { service } from '@/shared/configs/information-types.config'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import http from '@/shared/helpers/http'
import _ from 'lodash'
import getAll from '@/shared/configs/vuex/get-all.config'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class InvoiceEdit extends Vue {
  form: FormBase | any = null
  invoice: Invoice | any = null
  validNumber: boolean = true

  async created(): Promise<void> {
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/invoices')
      .setMethod(HttpMethod.PUT)
      .setUuid(this.$router.currentRoute.params.id)
      .setOnSetEntry((invoice: any) => {
        this.invoice = invoice

        this.$root.$emit('headingChange', {
          heading: __('views.invoices.edit.title', { invoices: this.title })
        })
      })
      .setModel(Invoice)
      .setCancel(() => this.$router.push({ name: GeneralRoutes.invoice, params: { id: this.$route.params.id } }))
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.invoice, params: { id: this.$route.params.id } })
      })
      .setFields([
        new SearchableField()
          .setKey('client_id')
          .setLoadItemSort('-created_at')
          .setRequiredDecoration(true)
          .setEntryKey('clientId')
          .setNotFoundComponent(ClientNotFound)
          .setDisplayKey('company_name')
          .setTitle(__('views.invoices.form.client'))
          .loadItems({
            endpoint: '/clients',
            value: 'id',
            title: 'company_name'
          }),
        new Field()
          .setKey('number')
          .setOnChange(_.debounce(this.checkIfValid, 400))
          .setTitle(__('views.invoices.form.number'))
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.date)
          .setKey('date')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.date'))
          .setSize(FieldSizes.half),
        new ArrayField()
          .setKey('items')
          .setShowTotals(true)
          .setFieldsTemplates({ service }),
        new Field()
          .setType(FieldTypes.date)
          .setKey('due_date')
          .setRequiredDecoration(true)
          .setEntryKey('dueDate')
          .setTitle(__('views.invoices.form.dueDate'))
          .setSize(FieldSizes.half),
        new SearchableField()
          .setKey('manager_id')
          .setEntryKey('managerId')
          .setDisplayKey('full_name')
          .setRequiredDecoration(true)
          .setTitle(__('views.invoices.form.manager'))
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/users',
            value: 'id',
            title: 'full_name',
            perPage: 20,
            filters: []
          }),
        new Field()
          .setKey('locale')
          .setTitle(__('views.invoices.form.language'))
          .setType(FieldTypes.checkbox)
          .setCheckedValue('en')
          .setSize(FieldSizes.fourTwelfth)
          .setUncheckedValue('lt'),
        new Field()
          .setKey('send_email')
          .setTitle(__('views.invoices.form.send_email'))
          .setSize(FieldSizes.fourTwelfth)
          .setType(FieldTypes.checkbox),
        new Field()
          .setKey('add_sale_deed')
          .setTitle(__('views.invoices.form.add_sale_deed'))
          .setSize(FieldSizes.fourTwelfth)
          .setType(FieldTypes.checkbox),
        new Field()
          .setKey('notes')
          .setEntryKey('notes')
          .setTitle(__('views.invoices.form.notes'))
          .setType(FieldTypes.richEditor)
      ])
      .setChangeDataBeforeSubmit((data: any) => ({
        ...data,
        status: this.invoice?.status,
        type: this.invoice?.type
      }))
  }

  checkIfValid(form: FormBase): any {
    if (form.data.number && form.data.number !== this.invoice?.number) {
      http.get(`invoices/check-is-available/${form.data.number}`).then((response: IResponse<IModelResponse>) => {
        if (!response.data) this.validNumber = false
        else this.validNumber = true
      })
    }

    if (form.data.number === this.invoice?.number) this.validNumber = true
  }

  get title(): string {
    return this.invoice ? this.invoice.number : ''
  }
}
