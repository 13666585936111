import http from '@/shared/helpers/http'
import { AxiosResponse } from 'axios'
import { IOfferPdfOption } from '@/modules/offer/interfaces/offer-response.interface';

export default class OfferService {
  static async updateStatus(offerId: number, data: any): Promise<AxiosResponse> {
    return http.put(`offers/${offerId}/status`, data).then((response: any) => response.data)
  }

  static async getPdfOptions(): Promise<IOfferPdfOption[]> {
    return http.get('offer-pdf/options').then((response: any) => response.data.data)
  }

  static async downloadPdf(offerId: string, type: string, locale: string): Promise<AxiosResponse> {
    return http.get(`offer-pdf/${offerId}/${type}/${locale}`, { responseType: 'blob' })
  }
}
