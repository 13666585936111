import Field from '@/shared/classes/components/form/field'

export enum FilterOperators {
  equals = 'equals',
  like = 'like',
  in = 'in',
  notIn = 'notIn',
  nulled = 'nulled',
  notNulled = 'notNulled',
  gte = 'gte',
  lte = 'lte',
  equalsYear = 'equalsYear'
}

export default class DataTableFilter {
  field!: Field
  title!: string
  keyIsPrimary: boolean = false
  showCondition: boolean = true
  visibleValuesCount: number = 2
  operator: FilterOperators = FilterOperators.equals
  filterReturnParam: (data: any, operator: FilterOperators, field: any) => void =
    (data: any, operator: FilterOperators, field: any) => {
      if (data && data.length) return { [`filter.${operator}.${field.key}`]: data }
    }

  filterSetValue: (field: any, data: any, operator?: string) => void =
    (field: any, data: any) => {
      field.value = data
    }

  setField(field: Field): this {
    this.field = field
    return this
  }

  setTitle(title: string): DataTableFilter {
    this.title = title
    return this
  }

  setOperator(operator: FilterOperators): DataTableFilter {
    this.operator = operator
    return this
  }

  setKeyIsPrimary(): this {
    this.keyIsPrimary = true
    return this
  }

  setShowCondition(showCondition: boolean): this {
    this.showCondition = showCondition
    return this
  }

  setVisibleValuesCount(visibleValuesCount: number): this {
    this.visibleValuesCount = visibleValuesCount
    return this
  }

  setFilterReturnParam(filterReturnParam: (data: any, operator: FilterOperators, field: any) => void): this {
    this.filterReturnParam = filterReturnParam
    return this
  }

  setFilterSetValue(filterSetValue: (field: any, data: any, operator?: string) => void): this {
    this.filterSetValue = filterSetValue
    return this
  }
}
