

















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { GeneralRoutes } from '@/router/routes/general'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Offer from './models/offer.model'
import ArrayField from '@/shared/classes/components/form/fields/array-field'
import { changeData, heading, vatlessService } from '../../shared/configs/information-types.config'
import { HttpMethod } from '@/helpers/requests'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import http from '@/shared/helpers/http'
import _ from 'lodash'
import getAll from '@/shared/configs/vuex/get-all.config'
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import OffersSaveAsTemplate from '@/modules/offer/OffersSaveAsTemplate.vue';
import SelectField from '@/shared/classes/components/form/fields/select-field';
import SelectOption from '@/shared/classes/components/form/select-option';
import { InvoiceStatusKeysData, OfferStatusKeys } from '@/shared/configs/offer.config';

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class OffersEdit extends Vue {
  validNumber: boolean = true
  offer: Offer | any = null
  form: FormBase | any = null
  reportTypes: SelectOption[] = []

  getReportTypes() {
    http.get('/offers/options').then(response => {
      response.data.data.forEach((item: any) => {
        this.reportTypes.push(
          new SelectOption()
            .setKey(item.key)
            .setTitle(item.value)
        )
      })
    })
  }

  async created(): Promise<void> {
    this.getReportTypes()
    await getAll(this.$store)

    this.form = new FormBase()
      .setEndpoint('/offers')
      .setUuid(this.$router.currentRoute.params.id)
      .setOnSetEntry((offer: any) => {
        this.offer = offer
        this.$root.$emit('headingChange', {
          heading: __('views.offers.edit.title', { offer: this.title })
        })
      })
      .setMethod(HttpMethod.PUT)
      .setModel(Offer)
      .setCancel(() => this.$router.push({ name: GeneralRoutes.offer, params: { id: this.$route.params.id } }))
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.offer, params: { id: this.$route.params.id } })
      })
      .setFields([
        new SearchableField()
          .setKey('client_id')
          .setLoadItemSort('-created_at')
          .setNotFoundComponent(ClientNotFound)
          .setRequiredDecoration(true)
          .setEntryKey('clientId')
          .setDisplayKey('company_name')
          .setTitle(__('views.offers.form.client'))
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/clients',
            value: 'id',
            title: 'company_name'
          }),
        new SelectField()
          .setKey('status')
          .setRequiredDecoration(true)
          .setMultiple(false)
          .setTitle('Statusas')
          .setType(FieldTypes.select)
          .setSelectOptions([
            new SelectOption()
              .setKey(OfferStatusKeys.active)
              .setTitle(InvoiceStatusKeysData[OfferStatusKeys.active]),
            new SelectOption()
              .setKey(OfferStatusKeys.approved)
              .setTitle(InvoiceStatusKeysData[OfferStatusKeys.approved]),
            new SelectOption()
              .setKey(OfferStatusKeys.refused)
              .setTitle(InvoiceStatusKeysData[OfferStatusKeys.refused]),
            new SelectOption()
              .setKey(OfferStatusKeys.suspended)
              .setTitle(InvoiceStatusKeysData[OfferStatusKeys.suspended])
          ])
          .setSize(FieldSizes.half),
        new Field()
          .setKey('date')
          .setTitle(__('views.offers.form.date'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half)
          .setType(FieldTypes.date),
        new Field()
          .setKey('due_date')
          .setEntryKey('dueDate')
          .setTitle(__('views.offers.form.due-date'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half)
          .setType(FieldTypes.date),
        new Field()
          .setKey('number')
          .setOnChange(_.debounce(this.checkIfValid, 400))
          .setTitle(__('views.offers.form.number'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('title')
          .setTitle(__('views.offers.form.title'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new ArrayField()
          .isDraggable()
          .setKey('information')
          .setAddKeys(['heading', 'service'])
          .setShowTotals(true)
          .setFieldsTemplates({
            heading,
            service: vatlessService
          }),
        new SearchableField()
          .setKey('manager_id')
          .setRequiredDecoration(true)
          .setEntryKey('managerId')
          .setDisplayKey('full_name')
          .setTitle(__('views.offers.form.manager'))
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/users',
            value: 'id',
            title: 'full_name'
          }),
        new SelectField()
          .setKey('report_type')
          .setRequiredDecoration(true)
          .setMultiple(false)
          .setTitle(__('views.offers.form.report_type'))
          .setType(FieldTypes.select)
          .setSelectOptions(this.reportTypes)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('locale')
          .setTitle(__('views.offers.form.language'))
          .setType(FieldTypes.checkbox)
          .setCheckedValue('en')
          .setUncheckedValue('lt'),
        new Field()
          .setKey('notes')
          .setTitle('Pastabos')
          .setType(FieldTypes.richEditor)
      ])
      .setChangeDataBeforeSubmit(changeData)
  }

  async changeTemplate(): Promise<void> {
    await http
      .get(`offer-templates/${this.form.data.template_id}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        const group: any = _.find(this.form.fields, (field: any) => field.key === 'information')
        group.children = []
        item.data.information.forEach((row: any) => {
          group.addChildren(group.fieldTemplates[row.type], row.type, this.form)
        })
        this.form.setData({
          ...this.form.data,
          title: item.data.title,
          information: item.data.information
        })
      })
  }

  checkIfValid(form: FormBase): any {
    if (form.data.number && form.data.number !== this.offer?.number) {
      http.get(`invoices/check-is-available/${form.data.number}`).then((response: IResponse<IModelResponse>) => {
        if (!response.data) this.validNumber = false
        else this.validNumber = true
      })
    }

    if (form.data.number === this.offer?.number) this.validNumber = true
  }

  openSaveTemplateModal(): void {
    this.$store.commit('setModalDialog', {
      component: OffersSaveAsTemplate,
      id: this.offer.id,
      props: {
        title: __('views.offers.save-template.title'),
        size: 'md',
        submitLabel: __('form.save')
      },
      details: {
        data: {
          information: this.processInformation(this.form.data.information),
          notes: this.form.data.notes
        }
      }
    })
  }

  processInformation(information: any) {
    if (typeof information !== 'object') {
      return information
    }
    const output: {[k: string]: any} = {}
    Object.keys(information).forEach((key) => {
      output[key] = {
        ...information[key],
        position: key
      }
    })
    return output
  }

  get title(): string {
    return this.offer ? this.offer.title : ''
  }
}
