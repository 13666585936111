import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import _ from 'lodash'
import FormBase from '../form-base'

export default class FormGroup extends Field {
  type: FieldTypes = FieldTypes.array
  children: any = []
  groupSize: FieldSizes = FieldSizes.full
  groupTitle: string = ''
  fieldTemplates: Object = {}
  repeating: boolean = true
  addKeys: string[] = []
  initRows: boolean = false
  createNewRow: boolean = false
  appendData: any = null
  groupLocalData: any = {}
  showTotals: boolean = false

  isDraggable(): this {
    this.type = FieldTypes.arrayDrag
    return this
  }

  setChildren(children: Field[]): this {
    this.children = children
    return this
  }

  setGroupSize(groupSize: FieldSizes): this {
    this.groupSize = groupSize
    return this
  }

  setTitle(title: string): this {
    this.groupTitle = title
    return this
  }

  setShowTotals(showTotals: boolean): this {
    this.showTotals = showTotals
    return this
  }

  setFieldsTemplates(fields: Object): this {
    this.fieldTemplates = fields
    return this
  }

  setRepeating(repeating: boolean): this {
    this.repeating = repeating
    return this
  }

  setCreateNewRow(createNewRow: boolean): this {
    this.createNewRow = createNewRow
    return this
  }

  setAppendData(appendData: any): this {
    this.appendData = appendData
    return this
  }

  setAddKeys(keys: string[]): this {
    this.addKeys = keys
    return this
  }

  setGroupLocalData(groupLocalData: any): this {
    this.groupLocalData = groupLocalData
    return this
  }

  addChildren(fields: Field[], key: string, form: FormBase, index?: string): this {
    const flatChildren = _.flatten(this.children)
    let lastIndex: any = ''
    if (index) lastIndex = index
    else lastIndex = flatChildren.length ? Math.max(...flatChildren.map((child: any) => child.index)) + 1 : 0

    this.children.push([
      ..._.cloneDeep(
        fields.map((field: Field) => {
          field.setKey(`${this.key}.${lastIndex}${field.arrayKey ? '.' + field.arrayKey : ''}`).setIndex(lastIndex)

          const groupData: any = form.data[this.key]
          if (groupData) {
            const fieldData: any = groupData[field.index]

            form.setData({
              ...form.data,
              [this.key]: {
                ...groupData,
                [field.index]: {
                  ...fieldData,
                  type: key,
                  ...this.appendData
                }
              }
            })
          }
          return field
        })
      )
    ])
    return this
  }

  setData(data: any): this {
    Object.keys(data).forEach(key => {
      const field: Field | undefined = this.children.find((child: Field) => child.key === key)
      if (field) field.setValue(data[field.key])
    })
    return this
  }

  removeFields(index: any): void {
    this.children.splice(index, 1)
  }

  setInitRows(initRows: boolean): this {
    this.initRows = initRows
    return this
  }
}
