











import { Component, Vue } from 'vue-property-decorator'
import ProcurementSelect from '@/modules/procurement/ProcurementSelect.vue';
import { GeneralRoutes } from '@/router/routes/general';
import ProcurementService from '@/services/ProcurementService';
import __ from '@/helpers/__';

@Component({
  components: { ProcurementSelect },
  methods: { __ }
})
export default class ProcurementSettings extends Vue {
  private saveCategories(categories: string[]): void {
    const procurements: object[] = []
    categories.forEach(category => {
      procurements.push({ code: category })
    })
    ProcurementService.saveCategories({ procurements }).then(() => {
      this.$router.push({
        name: GeneralRoutes.procurementIndex,
        query: { categories }
      })
    })
  }
}
