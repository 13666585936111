














import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import { Submittable } from '@/shared/interfaces/submittable';
import OffersSaveAsTemplateSuccess from '@/modules/offer/OffersSaveAsTemplateSuccess.vue';

@Component({
  methods: { __ }
})
export default class OffersSaveAsTemplate extends Vue implements Submittable {
  @Prop() itemDetails!: any
  error: any = null
  templateTitle: string = ''

  submitDialog(): void {
    this.error = null
    http.post('/offer-templates', {
      ...this.itemDetails.data,
      title: this.templateTitle
    }).then(() => {
      this.$store.commit('setModalDialog', {
        component: OffersSaveAsTemplateSuccess,
        id: 'offerSuccess',
        props: {
          title: __('views.offers.save-template.title'),
          size: 'md'
        },
        hideFooter: true
      })
    }).catch((error: any) => {
      this.error = error
    })
  }
}
