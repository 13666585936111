






import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import Client from '@/modules/client/models/client.model'
import refreshTable from '@/shared/helpers/data-table/refresh-table'
import ClientsCreate from '@/modules/client/ClientsCreate.vue';
import { tableCount } from '@/helpers/tableCount';

@Component({
  components: { ClientsCreate, DataTable, PageHeader },
  methods: { __ }
})
export default class ClientsIndex extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.clientsIndex && this.$refs.table) {
      refreshTable(this.$refs.table)
    }
  }

  created() {
    this.$root.$on('openAddClientModal', this.openAddNewModal)
  }

  table: DataTableBase = new DataTableBase()
    .setId(tableCount.tableID.clientsIndex)
    .setModel(Client)
    .setEndpoint('/clients')
    .setRowClickCallback((item: Client) => {
      this.$router.push({ name: GeneralRoutes.client, params: { id: item.id.toString() } })
    })
    .setHeaders([
      new DataTableHeader()
        .setKey('companyName')
        .setLabel(__('views.clients.index.table.columns.company_name'))
        .setLink({ link: GeneralRoutes.client, linkParam: 'id' }),
      new DataTableHeader().setKey('companyAddress').setLabel(__('views.clients.index.table.columns.company_address')),
      new DataTableHeader()
        .setKey('contactFullName')
        .setLabel(__('views.clients.index.table.columns.contact_full_name')),
      new DataTableHeader()
        .setKey('phone')
        .setEntryKey('phone')
        .setLabel(__('views.clients.index.table.columns.phone'))
        .setHrefLink((data: Client) => `tel:${data.phone}`),
      new DataTableHeader()
        .setKey('companyEmail')
        .setLabel(__('views.clients.index.table.columns.company_email'))
        .setHrefLink((data: Client) => `mailto:${data.companyEmail}`)
    ])

  openAddNewModal(): void {
    this.$store.commit('setModalDialog', {
      component: ClientsCreate,
      props: {
        title: __('views.clients.create.title'),
        onSuccess: () => {
          refreshTable(this.$refs.table)
        }
      }
    })
  }
}
