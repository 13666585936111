import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import ISettingResponse from '@/modules/settings/interfaces/setting-response.interface'

export default class Settings extends Model<IModelResponse> {
  static ALIAS: string = 'setting'

  key!: string
  value!: string

  transform(data: ISettingResponse): void {
    super.transform(data)

    this.key = data.key
    this.value = data.value
    this.id = data.id
  }
}
