

















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'

@Component({
  methods: { __ }
})
export default class ModalNavigationable extends Vue {
  @Prop() title!: string
  @Prop() labelOk!: string
  @Prop() labelCancel!: string
  @Prop({ default: false }) hideFooter!: boolean
  @Prop({ default: 'lg' }) modalSize!: string

  defaultModalDisappear(bvModalEvent: any) {
    bvModalEvent.preventDefault()
    this.$router.go(-1)
  }

  onHide(bvModalEvent: any) {
    this.defaultModalDisappear(bvModalEvent)
  }
}
