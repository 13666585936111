



import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import { GeneralRoutes } from '@/router/routes/general'
import _ from 'lodash'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  methods: { __ }
})
export default class DeleteComponent extends Vue implements Submittable {
  @Prop() itemId!: number
  @Prop() itemType!: string
  data: any = null
  GeneralRoutes = GeneralRoutes

  async created(): Promise<void> {
    await http
      .get(`${this.itemType}`)
      .then((response: IResponse<IModelResponse>) => response.data)
      .then((item: IResponse<IModelResponse>) => {
        this.data = _.find(item.data, (item: any) => item.id === this.itemId)
      })
  }

  get title(): string {
    return this.data ? this.data.title : ''
  }

  submitDialog(): void {
    http.delete(`${this.itemType}/${this.itemId}`).then(() => {
      this.$root.$emit('formSuccess')
    })
  }
}
