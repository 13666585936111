





import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import Service from '@/modules/service/models/service.model'
import { HttpMethod } from '@/helpers/requests'
import { GeneralRoutes } from '@/router/routes/general'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import { setPriceWithVat } from '@/shared/configs/service.config'
import { UnitGetters } from '@/store/modules/unit/unit.getters'
import { VatGetters } from '@/store/modules/vat/vat.getters'
import { Submittable } from '@/shared/interfaces/submittable';
import { VatActions } from '@/store/modules/vat/vat.actions';
import { UnitActions } from '@/store/modules/unit/unit.actions';

@Component({
  components: { Form },
  methods: { __ }
})
export default class ServicesEdit extends Vue implements Submittable {
  @Prop() itemId!: string
  service: Service | null = null
  form: FormBase = new FormBase()

  created(): void {
    this.$store.dispatch(VatActions.fetch)
    this.$store.dispatch(UnitActions.fetch)

    this.form = new FormBase()
      .setEndpoint('/services')
      .setMethod(HttpMethod.PUT)
      .setUuid(this.itemId)
      .setModel(Service)
      .setOnSetEntry((service: any) => {
        this.service = service
      })
      .setOnFinally((form: any) => setPriceWithVat(form))
      .setOnSuccess(() => {
        this.$router.push({ name: GeneralRoutes.servicesIndex })
      })
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('title')
          .setTitle(__('views.services.form.title'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half),
        new Field()
          .setKey('amount')
          .setTitle(__('views.services.form.amount'))
          .setSize(FieldSizes.half)
          .setRequiredDecoration(true)
          .setType(FieldTypes.number)
          .setMinValue(0)
          .setOnChange(setPriceWithVat),
        new Field()
          .setKey('price_per_unit')
          .setEntryKey('pricePerUnit')
          .setTitle(__('views.services.form.price'))
          .setRequiredDecoration(true)
          .setSize(FieldSizes.half)
          .setType(FieldTypes.number)
          .setMinValue(0)
          .setOnChange(setPriceWithVat),
        new SearchableField()
          .setKey('unit')
          .setVuexGetter(UnitGetters.getUnits)
          .setNoUuid(true)
          .setTitle(__('views.invoices.form.units'))
          .setRequiredDecoration(true)
          .setValueKey('title')
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/units',
            value: 'id',
            title: 'title'
          }),
        new SearchableField()
          .setKey('vat')
          .setVuexGetter(VatGetters.getVats)
          .setNoUuid(true)
          .setTitle(__('views.invoices.form.vats'))
          .setValueKey('percentage')
          .setRequiredDecoration(true)
          .setOnChange(setPriceWithVat)
          .setSize(FieldSizes.half)
          .loadItems({
            endpoint: '/vats',
            value: 'id',
            title: 'title'
          }),
        new Field()
          .setKey('price_with_vat')
          .setEntryKey('priceWithVat')
          .setTitle(__('views.services.form.price_with_vat'))
          .setSize(FieldSizes.half)
          .setDisabled(true)
      ])
  }

  get title(): string {
    return this.service ? this.service.title : ''
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
