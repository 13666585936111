



import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import Vat from '@/modules/settings/models/vat.model'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import { Submittable } from '@/shared/interfaces/submittable';

@Component({
  components: { Form },
  methods: { __ }
})
export default class VATCreate extends Vue implements Submittable {
  form: FormBase | any = null

  created(): void {
    this.form = new FormBase()
      .setEndpoint('/vats')
      .setModel(Vat)
      .setNoSubmitButton(true)
      .setFields([
        new Field()
          .setKey('title')
          .setTitle(__('views.settings.create.vat-title'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('percentage')
          .setTitle(__('views.settings.create.vat-percentage'))
          .setSize(FieldSizes.half)
      ])
  }

  submitDialog(): void {
    const ref: any = this.$refs.form
    ref.submit()
  }
}
