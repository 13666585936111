

































import { Component, Vue, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import ReminderTemplate from '@/modules/reminder-template/models/reminder-template.model'
import ReminderTemplateService from '@/services/ReminderTemplateService'
import SelectOption from '@/shared/classes/components/form/select-option'
import { GeneralRoutes } from '@/router/routes/general'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ReminderTemplatesEdit extends Vue {
  @Prop() meta!: any
  data: ReminderTemplate | any = {}
  error: any = {}
  tags: string[] = [
    '{client_company_name}',
    '{client_contact_full_name}',
    '{invoice_number}',
    '{invoice_total_with_vat}',
    '{invoice_date}',
    '{invoice_due_date}',
    '{invoice_left_pay}',
    '{url}'
  ]

  options: SelectOption[] = [
    new SelectOption().setKey('every_day').setTitle(__('reminder-periods.daily')),
    new SelectOption().setKey('every_week').setTitle(__('reminder-periods.weekly')),
    new SelectOption().setKey('every_month').setTitle(__('reminder-periods.monthly')),
    new SelectOption().setKey('every_year').setTitle(__('reminder-periods.yearly'))
  ]

  async created(): Promise<void> {
    this.data = await ReminderTemplateService.get(this.$route.params.id)
  }

  async submitReminderTemplate(): Promise<void> {
    await ReminderTemplateService.edit(this.$route.params.id, this.data)
      .then(() => this.$router.push({ name: GeneralRoutes.reminderTemplatesIndex }))
      .catch((error: any) => {
        this.error = error
      })
  }
}
